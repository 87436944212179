import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';

function Info() {

  const [numberVal, setNumberVal] = useState("#!");
  const copyAddress = () => {
    const copyTxt = document.querySelector('.copy_address').innerText;
    window.navigator.clipboard.writeText(copyTxt).then(() => {});
  };

  const copyEmail = () => {
    const copyTxt = document.querySelector('.copy_email').innerText;
    window.navigator.clipboard.writeText(copyTxt).then(() => {});
  };

  const onClickNumber = () => {
    if(isMobileOnly){
      setNumberVal("tel:02-2088-5084")
    }
  }

  return (
    <ul className="cont_info">
      <li>
        <a
          href="https://map.naver.com/v5/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A4%91%EA%B5%AC%20%ED%87%B4%EA%B3%84%EB%A1%9C27%EA%B8%B8%2028,%20%ED%95%9C%EC%98%81%EB%B9%8C%EB%94%A9%20601%ED%98%B8/address/14136646.959588345,4517788.375303093,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A4%91%EA%B5%AC%20%ED%87%B4%EA%B3%84%EB%A1%9C27%EA%B8%B8%2028,new?c=14136642.0597583,4517786.9159350,19,0,0,0,dh&isCorrectAnswer=true"
          target="_blank"
          className="copy_address"
        >
          서울특별시 중구 퇴계로27길 28, 한영빌딩 601호
        </a>
        <button
          type="button"
          title="주소 복사"
          className="btn_copy"
          onClick={copyAddress}
        >
          <i className="ico_20_copy"></i>
        </button>
      </li>
      <li>
          <a href={numberVal} onClick={onClickNumber} title="02-2088-5084">
            02-2088-5084
          </a>{' '}
          (FAX. 02-2088-5184)
      </li>
      <li>
        <a href="mailto:hello@insplanet.co.kr" className="copy_email">
          hello@insplanet.co.kr
        </a>
        <button
          type="button"
          title="이메일 복사"
          className="btn_copy"
          onClick={copyEmail}
        >
          <i className="ico_20_copy"></i>
        </button>
      </li>
    </ul>
  );
}

export default Info;
