import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from '../../../store';
import { closeModal } from './alertSlice';

const Alert = () => {

  const modal = useSelector((state) => state.modal);

  const dispatch = useAppDispatch();

  const onClickModalClose=()=>{
    dispatch(closeModal());
  }

  return (
    <div className="popup_wrap alert" data-popcont="alert1" style={modal.mode ? {display:'block'}: {display:'none'}}>
      <div className="inner">
        {/* title */}
        <div className="pop_header">
          <strong className="tlt">{modal.modalTitle}</strong>
        </div>
        <div className="pop_content">
          {modal.modalType}
        </div>
        <div className="pop_footer">
          <button type="button" className="btn_m_line" data-pop-confirm onClick={onClickModalClose}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default Alert;