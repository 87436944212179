import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../api/axios';
import requests from '../../../api/requests';

const initialState = {
    loading: false,
    poroject: false,
    join: false,
    isAdd: false
};

export const postProjects = createAsyncThunk('poroject/postProjects', async projectData => {
    const res = await axios.post(requests.REQUEST_PROJECT, projectData, { headers: {"Content-Type": "multipart/form-data"}});
    let projectsData = res.data.data;
    return projectsData;
});

export const postJoins = createAsyncThunk('join/postJoins', async joinData => {
    const res = await axios.post(requests.REQUEST_JOIN, joinData, { headers: {"Content-Type": "multipart/form-data"}});
    let joinsData = res.data.data;
    return joinsData;
});

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        actIsAdd: (state, { payload }) => {
            state.isAdd = payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(postProjects.pending, state => {
                state.loading = true;
            })
            .addCase(postProjects.fulfilled, state => {
                state.loading = false;
                state.poroject = true;
            })
            .addCase(postProjects.rejected, state => {
                state.loading = false;
                state.poroject = false;
            })
    },
    extraReducers: builder => {
        builder
            .addCase(postJoins.pending, state => {
                state.loading = true;
            })
            .addCase(postJoins.fulfilled, state => {
                state.loading = false;
                state.join = true;
            })
            .addCase(postJoins.rejected, state => {
                state.loading = false;
                state.join = false;
            });
    },
});

export const { actIsAdd } = contactSlice.actions;
export default contactSlice;
