import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './assets/styles/style.scss';
import store from './store';
import ReactGA from 'react-ga';
import { GA } from '../src/components/common/config';

const TRACKING_ID = GA.TRACKING_ID;

const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize(TRACKING_ID);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
);
