export function headerScrollAction(
  scrollPosition,
  lastScrollPosition,
  viewAction
) {
  // scrollPosition : 현재 스크롤 값, lastScrollPosition : 이전 스크롤 값
  const DELTA = 10; // 스크롤 시 변경 가능 수치
  const headerWrap = document.querySelector('header');
  const headerHeight = headerWrap.offsetHeight;
  const headerBgTransparent = headerWrap.getAttribute('data-header');

  if (scrollPosition === 0) {
    scrollPosition += DELTA;
  }
  if (viewAction === 'load') {
    if (
      scrollPosition > headerHeight &&
      headerWrap.classList.contains('transparent')
    ) {
      headerWrap.classList.remove('transparent');
    }
  }
  // 이전 스크롤 값과 현재 스크롤 값이 10 이상 차이날 경우만 실행
  if (Math.abs(lastScrollPosition - scrollPosition) < DELTA) return;
  // Scroll
  if (
    scrollPosition > lastScrollPosition &&
    lastScrollPosition > headerHeight
  ) {
    headerWrap.classList.add('hidden');
    if (headerWrap.classList.contains('transparent')) {
      headerWrap.classList.remove('transparent');
    }
  } else {
    headerWrap.classList.remove('hidden');
    if (
      lastScrollPosition < headerHeight &&
      headerBgTransparent === 'bg-transparent'
    ) {
      headerWrap.classList.add('transparent');
    }
  }
}
