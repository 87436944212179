import React from "react";
import { useSelector } from "react-redux";
import { titleAction } from "../../../assets/js/textAnimation";
import {
  floatingAction,
  visualScrollAction,
} from "../../../assets/js/visualScrollAction.js";
import ScrollAni from "../../common/ScrollAni.js";
import Alert from "./Alert";
// import BtnWrap from './BtnWrap.js';
import FilterItem from "./FilterItem.js";
import FilterList from "./FilterList.js";

const Contact = () => {
  let scrollPosition = window.pageYOffset;
  let scrollPrev = 0;

  window.addEventListener("scroll", function () {
    if (window.innerWidth >= 720) {
      scrollPosition = window.pageYOffset;
      // visual 인터랙션
      visualScrollAction(scrollPosition, scrollPrev);
      scrollPrev = scrollPosition;
    } else {
      scrollPosition = window.pageYOffset;
      // visual 인터랙션
      floatingAction(scrollPosition, scrollPrev);
      scrollPrev = scrollPosition;
    }
  });
  // title animation
  setTimeout(() => {
    const textElements = document.querySelectorAll(
      "[data-action='action-title-y']"
    );
    titleAction("y", textElements, 0.5, { default: 0.3, repeat: 0.2843 });
  }, 0.5);

  const contactList = useSelector((state) => state.contactList);

  // useEffect(() => {
  //   openPopup();
  // }, []);

  return (
    <div className="contact scroll">
      <Alert />
      <section className="content">
        <div className="visual layout1">
          <h2 className="tlt" data-action="action-title-y">
            CONTACT
          </h2>
          {/* <BtnWrap /> */}
        </div>
        <div className="page" data-action="action-title-y">
          {/* visual 영역 */}
          <div className="left">
            <FilterList />
            <div className="sub_visual" data-cont-floating>
              <strong className="tlt">CONTACT</strong>
              <FilterList />
            </div>
            <div className="sub_btn" data-cont-floating>
              <a
                href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/insplanet"
                target="_blank"
                rel="noreferrer"
                title="페이지 이동"
                className="btn_floating"
                style={{ display: contactList.ListTwo ? "block" : " none" }}
              >
                진행중인 채용공고가 있어요<i className="ico_10_float"></i>
              </a>
            </div>
          </div>

          <FilterItem />
        </div>
        <a
          href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/insplanet"
          target="_blank"
          rel="noreferrer"
          title="페이지 이동"
          className="btn_floating mo"
          style={{ display: contactList.ListTwo ? "block" : " none" }}
        >
          진행중인 채용공고가 있어요<i className="ico_10_float"></i>
        </a>
      </section>
      <ScrollAni />
    </div>
  );
};

export default Contact;
