import { disableBodyScroll } from 'body-scroll-lock';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import BriefFile from '../../assets/file/insplanet_brief.pdf';

const Nav = () => {
  const gnbOpen = useCallback(() => {
    const inner = document.querySelector('.nav_inner');
    inner.classList.add('active');
    disableBodyScroll(inner);

    // 현재 location check && active
    let location = window.location.href.split('/');
    const menuList = document.querySelectorAll('.nav_inner .menu');

    location.map((href) =>
      menuList.forEach((menu) => {
        if (href === menu.innerText.toLowerCase()) {
          menu.classList.add('active');
        }
      })
    );
  }, []);

  return (
    <nav className="nav">
      <ul>
        <li>
          <Link to="/project" title="페이지 이동" className="menu">
            PROJECT
          </Link>
        </li>
        <li>
          <Link to="/company" title="페이지 이동" className="menu">
            COMPANY
          </Link>
        </li>
        <li>
          <Link to="/culture" title="페이지 이동" className="menu">
            CULTURE
          </Link>
        </li>
        <li>
          <Link to="/contact" title="페이지 이동" className="menu">
            CONTACT
          </Link>
        </li>
        <li>
          <a
            href={BriefFile}
            role="button"
            title="다운로드"
            rel="noreferrer"
            target="_blank"
            className="btn_o_m_second"
          >
            BROCHURE<i className="ico_16_down"></i>
          </a>
        </li>
        <li>
          <button
            type="button"
            title="메뉴 열기"
            className="btn_ham_menu"
            onClick={gnbOpen}
          >
            <i className="ico_32_gnbMenu"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
