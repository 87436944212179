export function visualScrollAction(
  scrollPosition,
  lastScrollPosition,
  viewAction
) {
  // scrollPosition : 현재 스크롤 값, lastScrollPosition : 이전 스크롤 값
  const DELTA = 10; // 스크롤 시 변경 가능 수치
  const visual = document.querySelectorAll('[data-cont-floating]');

  visual.forEach((visual) => {
    const visualHeight = visual.offsetHeight;
    const trigger = document.querySelector('.page').offsetTop;

    if (scrollPosition === 0) {
      scrollPosition += DELTA;
    }
    // 이전 스크롤 값과 현재 스크롤 값이 10 이상 차이날 경우만 실행
    if (Math.abs(lastScrollPosition - scrollPosition) < DELTA) return;
    // Scroll
    if (scrollPosition >= trigger) {
      if (
        scrollPosition > lastScrollPosition &&
        lastScrollPosition > visualHeight
      ) {
        visual.classList.remove('move');
        visual.classList.add('fixed');
      } else {
        visual.classList.add('move');
      }
    } else {
      visual.classList.remove('move');
      visual.classList.remove('fixed');
    }
  });
}

export function floatingAction(scrollPosition, lastScrollPosition, viewAction) {
  // scrollPosition : 현재 스크롤 값, lastScrollPosition : 이전 스크롤 값
  const DELTA = 10; // 스크롤 시 변경 가능 수치
  const target = document.querySelector('.btn_floating.mo');
  const visualHeight = target.offsetHeight;

  if (scrollPosition === 0) {
    scrollPosition += DELTA;
  }
  // 이전 스크롤 값과 현재 스크롤 값이 10 이상 차이날 경우만 실행
  if (Math.abs(lastScrollPosition - scrollPosition) < DELTA) return;
  // Scroll
  if (
    scrollPosition > lastScrollPosition &&
    lastScrollPosition > visualHeight
  ) {
    target.classList.add('active');
  } else {
    target.classList.remove('active');
  }
}
