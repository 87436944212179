export const baseUrl = process.env.REACT_APP_API_ROOT;

const requests = {
    //카테고리공통코드
    COMMON_GET_CATE: '/api/commonCode',

    //MAIN
    MAIN_PROJECT_LIST: '/api/projectMain',

    //PROJECT
    PROJECT_LIST: '/api/projectAll',
    //PROJECT_DETAIL: '/api/detail/:prjSeq',
    //PROJECT_DETAIL: '/api/detail/project?prjSeq=',
    PROJECT_DETAIL: '/api/project/detail/',

    //CULTURE
    CULTURE_LIST: '/api/cultureAll',

    //CONTACT
    REQUEST_PROJECT: '/api/contact/project',
    REQUEST_JOIN: '/api/contact/join',

    NOTICE_LIST: '/api/notice',
};

export default requests;
