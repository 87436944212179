import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AxiosInterceptor } from "../src/api/axios";
import RouteChangeTracker from "./components/common/RouteChangeTracker";

import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";

import ScrollToTop from "./components/common/ScrollToTop";
import Company from "./components/pages/company/Index";
import Contact from "./components/pages/contact/Index";
import Culture from "./components/pages/culture/Index";
import Main from "./components/pages/main/Main";
import Notice from "./components/pages/notice/Index";
import ProjectDetail from "./components/pages/project/Detail";
import Project from "./components/pages/project/Index";

// popup
import PerCollectPopup from "./components/common/PerCollectPopup";
import PerInfoPopup from "./components/common/PerInfoPopup";
import PerUsePopup from "./components/common/PerUsePopup";

//404 error
import NoPage from "./components/common/NoPage";
//500 error
import NoAccess from "./components/common/NoAccess";

// mobile check
import { isMobile, MobileView } from "react-device-detect";

function App() {
  RouteChangeTracker();
  const location = useLocation();

  useEffect(() => {
    // 모바일 높이값계산
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // 현재 location 체크
    const nowLoca = location.pathname;
    const prjDetail = "detail";
    const wrap = document.querySelector(".wrap");

    if (nowLoca.indexOf(prjDetail) !== -1) {
      wrap.classList.add("bg_bk");
    } else {
      wrap.classList.remove("bg_bk");
    }
  }, [location]);

  return (
    <div className={isMobile ? "wrap mo" : "wrap"}>
      <div className="ta_bg"></div> {/*추가됨*/}
      <MobileView className="mo_bg"></MobileView>
      <Header />
      <AxiosInterceptor>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/project" element={<Project />} />
          <Route path="/project/detail/:prjSeq" element={<ProjectDetail />} />
          <Route path="/company" element={<Company />} />
          <Route path="/culture" element={<Culture />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="*" element={<NoPage />} />
          <Route path="/noAccess" element={<NoAccess />} />
        </Routes>
      </AxiosInterceptor>
      <Footer />
      {/* popup */}
      <PerInfoPopup />
      <PerCollectPopup />
      <PerUsePopup />
    </div>
  );
}
export default App;
