import React from 'react';

function NoPage() {
  return (
    <div className="contain-page">
      <p className="text-information-head">
        원하시는 페이지를 찾을 수 없습니다.
      </p>
      <p className="text-information-body">
        찾으려는 웹페이지의 주소가 잘못 입력되었거나 <br /> 주소의 변경 또는
        삭제로 인해 사용하실 수 없습니다.
        <br /> 입력하신 링크정보, 접근 권한 등을 다시 한번 확인해 주세요.
      </p>
      <a href="/" className="link-home" title="메인으로 이동">
        인스플래닛 홈 가기
      </a>
    </div>
  );
}

export default NoPage;
