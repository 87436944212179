import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalTitle: '',
    modalType: '',
    mode: false,
  };
  
export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, actions) => {
      const { modalTitle, modalType } = actions.payload;
      state.modalTitle = modalTitle;
      state.modalType = modalType;
      state.mode = true;
    },
    closeModal: (state) => {
      state.modalTitle = '';
      state.modalType = '';
      state.mode = false;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export const selectModal = (state) => state.modal;

export default modalSlice.reducer;