import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../api/axios';
import requests from '../../../api/requests';

const initialState = {
    list: [],
    dataTotalCnt: '',
};

export const getMainProject = createAsyncThunk('main/getMainProject ', async () => {
    const res = await axios.get(requests.MAIN_PROJECT_LIST);
    let mainProjectData = res.data.data;
    return mainProjectData;
});

const mainSlice = createSlice({
    name: 'main',
    initialState,
    extraReducers: builder => {
        builder.addCase(getMainProject.fulfilled, (state, { payload }) => {
            state.list = payload.list;
            state.dataTotalCnt = payload.totalCount;
        });
    },
});

export default mainSlice;
