export function filterEvent(event) {
  // 대체 텍스트
  const span = document.createElement('span');
  const text = document.createTextNode('선택됨');
  span.appendChild(text);
  span.classList.add('blind');

  const tabTitle = document.querySelector('.selected_tlt');
  tabTitle.innerText = event.target.innerText.split('\n')[0];

  // 탭 리스트
  const tabList = document.querySelectorAll('.tab_list_wrap .list');

  tabList.forEach((list) => {
    list.setAttribute('tabindex', '-1');
    list.classList.remove('active');
  });
  if (window.innerWidth < 1280) {
    document.querySelector('.tab_list_wrap').classList.remove('active');
  }

  // content
  const all = document.querySelectorAll('[data-item]');

  tabList.forEach((list) => {
    if (list.querySelector('.blind')) {
      list.querySelector('.blind').remove();
    }
  });
  event.target.appendChild(span);
  event.target.setAttribute('tabindex', '0');
  event.target.classList.add('active');

  // content
  const val = event.target.dataset.list;

  all.forEach((content) => {
    const arr = content.dataset.item.split(' ');
    for (const a of arr) {
      if (a === val) {
        content.classList.add('active');
        break;
      } else {
        content.classList.remove('active');
      }
    }
  });
}
