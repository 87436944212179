import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import requests from '../../../api/requests';
import axios from '../../../api/axios';

const initialState = {
    list: [],
};

export const getProjectDetail = createAsyncThunk('project/getProjectDetail', async reqPrams => {
    if (reqPrams.cateCode) {
        const res = await axios.get(
            requests.PROJECT_DETAIL + reqPrams.prjSeq + '?prjTagCd=' + reqPrams.cateCode,
        );
        let project = res.data.data;
        return project;
    } else {
        //메인에서 들어온 경우 or 프로젝트의 all카테코리로 들어온경우
        const res = await axios.get(requests.PROJECT_DETAIL + reqPrams.prjSeq);
        let project = res.data.data;
        return project;
    }
});

const projectDetailSlice = createSlice({
    name: 'project',
    initialState,
    extraReducers: builder => {
        builder.addCase(getProjectDetail.pending, state => {
            state.list = [];
        });
        builder.addCase(getProjectDetail.fulfilled, (state, { payload }) => {
            state.list = payload;
        });
    },
});

export default projectDetailSlice;
