import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../api/requests";
import { filterEvent } from "../../../assets/js/tab";
import { titleAction } from "../../../assets/js/textAnimation";
import { useAppDispatch, useAppSelector } from "../../../store";
import Loading from "../../common/Loading";
import ScrollAni from "../../common/ScrollAni";
import { actIsAdd, getProject } from "./projectSlice";

const Project = () => {
  setTimeout(() => {
    const textElements = document.querySelectorAll(
      "[data-action='action-title-y']"
    );
    titleAction("y", textElements, 0.5, { default: 0.3, repeat: 0.2843 });
  }, 0.5);

  const openOption = () => {
    const tabTitle = document.querySelector(".selected_tlt");
    const tabOption = document.querySelector(".tab_list_wrap");

    tabTitle.classList.toggle("active");
    tabOption.classList.toggle("active");
  };

  const dispatch = useAppDispatch();

  const {
    cateList: projectCateList,
    list: projectList,
    totalPageCount,
  } = useAppSelector((state) => state.project);

  const { loading } = useAppSelector((state) => state.loading);

  let [pageIndex, setPageIndex] = useState(1);

  //프로젝트상세 링크로 넘길목적
  let [cateCode, setCateCode] = useState("");

  const selectCate = useCallback((e) => {
    setCateCode(e.target.dataset.list); //프로젝트상세 링크로 넘길목적
    return e.target.dataset.list;
  }, []);

  //초기렌더링
  useEffect(() => {
    dispatch(actIsAdd(false)); // all에서 스크롤내리고 상세 갔다가 왔을 때, 중복데이터 안쌓이게 하려고
    dispatch(getProject());
  }, []);

  const projectFilter = useCallback((selectCate) => {
    setCateCode(selectCate); //프로젝트상세 링크로 넘길목적
    setPageIndex(1);
    dispatch(actIsAdd(false));
    let reqParams = { cateCode: selectCate, pageIndex };
    dispatch(getProject(reqParams));

    const tabTitle = document.querySelector(".selected_tlt");
    // tabTitle.inner
    tabTitle.classList.remove("active");
  }, []);

  const { ref: target, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const lazyImg = useCallback(() => {
    setTimeout(() => {
      const projectItem = document.querySelectorAll(".tab_item_wrap li");
      projectItem.forEach((projectItem) => {
        projectItem.classList.add("active");
      });
    }, 300);
  }, []);

  useEffect(() => {
    if (inView && pageIndex <= totalPageCount) {
      let pageIncrease = pageIndex + 1;
      setPageIndex(pageIncrease);
      let reqPrams = { cateCode, pageIndex: pageIncrease };
      dispatch(actIsAdd(true));
      dispatch(getProject(reqPrams));
    }
  }, [inView]);

  return (
    <div className="project scroll">
      {loading && <Loading />}
      <section className="content">
        <div className="visual">
          <h2 className="tlt" data-action="action-title-y">
            project
          </h2>
          {/* FilterList */}
          <div data-action="action-title-y">
            <button
              type="button"
              className="selected_tlt"
              onClick={() => {
                openOption();
                projectFilter("");
              }}
            >
              ALL
            </button>
            <ul className="tab_list_wrap">
              <li onClick={() => projectFilter("")}>
                <button
                  type="button"
                  className="list active"
                  tabIndex="0"
                  onClick={filterEvent}
                >
                  All
                </button>
              </li>
              {projectCateList?.map((cate) => (
                <li key={cate.cd} onClick={(e) => projectFilter(selectCate(e))}>
                  <button
                    type="button"
                    className="list"
                    tabIndex="0"
                    onClick={filterEvent}
                    data-list={cate.cd}
                  >
                    {cate.cdNm}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* FilterItem  */}
        <ul className="tab_item_wrap">
          {projectList?.map((project) => (
            <li key={project.prjSeq}>
              <Link
                to={
                  project.prjDetailDispYn === "Y"
                    ? "/project/detail/" + project.prjSeq
                    : "/project"
                }
                state={{ cateCode }}
              >
                <div role="button" className="item">
                  {isMobile && project.mmainFile && project.mainFile ? (
                    <img
                      onLoad={lazyImg}
                      src={baseUrl + project.mmainFile}
                      alt=""
                    />
                  ) : (
                    <img
                      onLoad={lazyImg}
                      src={baseUrl + project.mainFile}
                      alt=""
                    />
                  )}
                  <div className="txt_wrap">
                    <h3 className="tlt">
                      {project.prjNm}
                      {project.prjDetailDispYn === "Y" ? null : (
                        <i className="ico_48_lock" />
                      )}
                    </h3>
                    <div className="device_info">
                      {project.prjTagCd?.map((tag, i) => (
                        <span className="name" key={i}>
                          {tag}
                        </span>
                      ))}
                      {project.prjProcStatusCd ? (
                        <span className="progress">-ING</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </section>
      <ScrollAni />
      <div ref={target}></div>
    </div>
  );
};
export default React.memo(Project);
