import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { baseUrl } from '../../../api/requests';
import { useAppDispatch, useAppSelector } from '../../../store';
import ScrollAni from '../../common/ScrollAni';
import { getProjectDetail } from './detailSlice';
import Loading from '../../common/Loading';
import { isMobile } from 'react-device-detect';

const ProjectDetail = () => {
    const location = useLocation();

    const cateCode = location.state?.cateCode;
    const fromMain = location.state?.prevPath;

    const { prjSeq } = useParams();
    const dispatch = useAppDispatch();

    const { list: project } = useAppSelector(state => state.projectDetail);
    const { loading } = useAppSelector(state => state.loading);

    useEffect(() => {
        dispatch(getProjectDetail({ prjSeq, cateCode }));
    }, [prjSeq, cateCode]);

    const [isMainFile, setIsMainFile] = useState(project.mainFile);

    return (
        <div className="project_detail">
            {loading && isMainFile ? (
                <Loading />
            ) : (
                <>
                    <section className="content">
                        <div
                            className={'visual'}
                            style={
                                isMobile
                                    ? { backgroundImage: `url(${baseUrl + project?.mmainFile})` }
                                    : { backgroundImage: `url(${baseUrl + project?.mainFile})` }
                            }
                        >
                            <div className="top">
                                <div className="item">
                                    <h2 className="tlt">{project?.prjNm}</h2>
                                    <div className="device_info">
                                        {project?.prjTagCd?.map((tag, i) => (
                                            <span className="name" key={i}>
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="bottom">
                                <ul className="date">
                                    <li>{project?.prjStartDt} ~ </li>
                                    <li>{project?.prjEndDt}</li>
                                    <li>{project?.orderCmpNm}</li>
                                </ul>
                                <ul className="award">
                                    {project?.prjAwardCd?.map((award, i) => (
                                        <li key={i} className={'ico_60_award' + award.charAt(4)}>
                                            <span className="blind">시상내역 아이콘</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <ScrollAni />
                        </div>
                    </section>
                    <div>
                        <div>
                            <div className="page">
                                <div className={project?.subPrjNm ? 'content' : 'content hidden'}>
                                    <strong className="sub_tlt">{project?.subPrjNm}</strong>
                                    <p
                                        className="desc"
                                        dangerouslySetInnerHTML={{ __html: project?.prjCont }}
                                    ></p>
                                </div>
                                {project?.detailFile?.map((imgUrl, i) => (
                                    <img
                                        src={baseUrl + imgUrl}
                                        key={i}
                                        alt="프로젝트 상세 이미지"
                                    />
                                ))}
                            </div>
                            {/* 페이지네이션 */}
                            <div className="pagenation">
                                {fromMain === '/' || project?.ppage === null ? null : (
                                    <Link to={project?.ppage} className="go_to_prev">
                                        <span
                                            href="/"
                                            role="button"
                                            title="이전으로"
                                            className="btn_txt"
                                        >
                                            <i className="ico_24_prev"></i>
                                            <span>PREV PROJECT</span>
                                        </span>
                                    </Link>
                                )}
                                <Link to="/project" className="go_to_list">
                                    <span role="button" title="메뉴목록으로" className="btn_o_line">
                                        <span>GO TO LIST</span>
                                        <i className="ico_24_goList"></i>
                                    </span>
                                </Link>
                                {fromMain === '/' || project?.npage === null ? null : (
                                    <Link to={project?.npage} className="go_to_next">
                                        <span
                                            href="/"
                                            role="button"
                                            title="다음으로"
                                            className="btn_txt"
                                        >
                                            <span>NEXT PROJECT</span>
                                            <i className="ico_24_next"></i>
                                        </span>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ProjectDetail;
