import React, { useEffect, useRef, Suspense } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import ScrollAni from '../../common/ScrollAni.js';
import bgImg from '../../../assets/images/img/img_main_graphic.png';
// script
import {
  contentAction,
  titleAction,
} from '../../../assets/js/textAnimation.js';
import { OrbitControls } from '@react-three/drei';
import { TextureLoader } from 'three';

const Intro = () => {
  useEffect(() => {
    // title animation
    setTimeout(() => {
      const textElements = document.querySelectorAll(
        "[data-action='action-title-y']"
      );
      titleAction('y', textElements, 0.5, { default: 0.3, repeat: 0.2843 });
    }, 2);

    contentAction('load', null);
    window.addEventListener('scroll', function () {
      contentAction('scroll', window.pageYOffset);
    });

    const tlt = document.querySelectorAll('.ani_tlt');
    let index = -1;
    setInterval(() => {
      index++;
      if (index >= tlt.length) {
        index = 0;
      }
      let nextIndex = index + 1;
      if (nextIndex === 3) {
        nextIndex = 0;
      }
      tlt[index].classList.remove('show');
      tlt[nextIndex].classList.add('show');
    }, 1300);
  }, []);

  function Ring() {
    const mesh = useRef(null);
    let size;
    let position;

    if (window.innerWidth < 720) {
      size = 2;
      position = [0, 0, 0];
    } else {
      size = 3;
      position = [1.5, 0, 0];
    }

    useFrame(() => {
      mesh.current.rotation.y = mesh.current.rotation.y += 0.003;
      mesh.current.rotation.x = mesh.current.rotation.x += 0.005;
    });
    const texture = useLoader(TextureLoader, bgImg);

    return (
      <mesh ref={mesh} position={position}>
        {/* 링 */}
        <torusGeometry args={[1.5, 1, 100, 200]} />
        <meshStandardMaterial
          attach="material"
          transparent={true}
          opacity={0.8}
          // color="blue"
          map={texture}
          metalness={0.4}
          roughness={0}
          clearcoat={0.8}
          distort={3}
        />
        {/* 물방울 */}
        {/* <Sphere visible args={[1, 100, 200]}>
          <MeshDistortMaterial
            // color="red"
            map={texture}
            attach="material"
            distort={0.5}
            speed={2}
            roughness={0}
          />
        </Sphere> */}
      </mesh>
    );
  }

  return (
    <div className="bg_box1">
      <Canvas>
        <ambientLight intensity={0.3} />
        <pointLight intensity={0.75} position={[5, 5, 5]} />
        <Ring />
        <Suspense fallback={null}>
          <Ring />
        </Suspense>
        <OrbitControls />
      </Canvas>

      <section className="content main_tlt">
        <div className="txt_box">
          <h2 className="tlt" data-action="action-title-y" data-text>
            <span className="ani_tlt show">Insight</span>
            <span className="ani_tlt">interest</span>
            <span className="ani_tlt">innovation</span>
          </h2>
          <p className="sub_tlt" data-action="action-title-y">
            강력한 통찰력과 창의적인 시각으로
            <br />
            새로운 가치를 만듭니다.
          </p>
        </div>
        <ScrollAni />
      </section>
    </div>
  );
};

export default Intro;
