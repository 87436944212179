import React, { useEffect } from 'react';
import Logo from './Logo.js';
import Nav from './Nav.js';
import InnerNav from './InnerNav.js';
import { headerScrollAction } from '../../assets/js/header.js';

const Header = () => {
  useEffect(() => {
    let scrollPosition = window.pageYOffset;
    const headerWrap = document.querySelector('header');
    let scrollPrev = 0;
    // 헤더스크롤 로드
    window.addEventListener('scroll', function () {
      scrollPosition = window.pageYOffset;
      // Header 인터랙션
      !!headerWrap && headerScrollAction(scrollPosition, scrollPrev);
      scrollPrev = scrollPosition;
    });
  }, []);

  if (window.location.pathname === '/noAccess') return null;

  return (
    <header>
      <Logo />
      <Nav />
      <InnerNav />
    </header>
  );
};

export default Header;
