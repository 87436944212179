export function indicateShow(scrollPosition, lastScrollPosition, viewAction) {
  // scrollPosition : 현재 스크롤 값, lastScrollPosition : 이전 스크롤 값
  const DELTA = 10; // 스크롤 시 변경 가능 수치
  const target = document.querySelector('.scroll_ani');

  if (scrollPosition === 0) {
    scrollPosition += DELTA;
  }
  // 이전 스크롤 값과 현재 스크롤 값이 10 이상 차이날 경우만 실행
  if (Math.abs(lastScrollPosition - scrollPosition) < DELTA) return;

  // Scroll
  if (scrollPosition > lastScrollPosition) {
    target.classList.add('hide');
    target.classList.remove('show');
  }
  if (scrollPosition < 20 && target.classList.contains('hide')) {
    target.classList.add('show');
  }
}
