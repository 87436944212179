import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { popupHide } from './popupSlice';

const PerCollectPopup = () => {
  const popup = useSelector((state) => state.popup);

  const dispatch = useAppDispatch();

  const onClickPopupClose = () => {
    dispatch(popupHide());
  };

  return (
    <div
      className={popup.collectMode ? 'popup_wrap active' : 'popup_wrap'}
      data-popcont="PerCollectPopup"
    >
      <div className="dim" onClick={onClickPopupClose}></div>
      <div className="inner">
        {/* title */}
        <div className="pop_header" id="PerCollectHeader">
          <strong className="tlt">개인정보 취급위탁에 대한 동의</strong>
        </div>
        {/* content */}
        <div className="pop_content" id="PerCollectContent">
          <p className="text-information1">
            회사는 수집된 개인정보를 이용자의 동의가 있는 경우에 한하여 아래와
            같이 제3자에 제공하고 있습니다.
          </p>

          <table className="table-type1">
            <caption className="blind">
              개인정보 취급위탁 목적, 제공받는 자의 명칭 제공되는 개인정보,
              제공기간
            </caption>
            <colgroup>
              <col width="168" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>제공 목적</th>
                <td>시스템 운영</td>
              </tr>
              <tr>
                <th>제공받는 자의 명칭</th>
                <td>인스플래닛㈜</td>
              </tr>
              <tr>
                <th>제공되는 개인정보</th>
                <td>
                  <ul className="list-type1">
                    <li>프로젝트 의뢰 : 성명, 이메일, 연락처, 회사명</li>
                    <li>입사지원 : 성명, 이메일, 연락처</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>제공 기간</th>
                <td>
                  <strong>접수 후 1년간 보유</strong>
                </td>
              </tr>
            </tbody>
          </table>

          <p className="text-information2">
            정보주체는 개인정보 수집에 대한 동의를 거부할 권리가 있습니다.
            <br />
            단, 미동의 시 문의 및 제안 서비스를 이용하실 수 없습니다.
          </p>
        </div>
        <div className="pop_footer">
          <div className="btn_wrap">
            <button
              type="button"
              className="btn_m_line"
              onClick={onClickPopupClose}
              data-pop-confirm
            >
              확인
            </button>
          </div>
        </div>
        <button
          type="button"
          title="팝업 닫기"
          className="btn_close"
          onClick={onClickPopupClose}
        ></button>
      </div>
    </div>
  );
};

export default PerCollectPopup;
