import React from "react";
import With from "./With";
import Insight from "./Insight";
import { contentAction, titleAction } from "../../../assets/js/textAnimation";
import ScrollAni from "../../common/ScrollAni.js";

const Company = () => {
  // title animation
  setTimeout(() => {
    const textElements = document.querySelectorAll(
      "[data-action='action-title-y']"
    );
    titleAction("y", textElements, 0.5, { default: 0.3, repeat: 0.2843 });
  }, 0.5);

  contentAction("load", null);
  window.addEventListener("scroll", function () {
    contentAction("scroll", window.pageYOffset);
  });

  return (
    <div className="company scroll">
      <Insight />
      <With />
      <ScrollAni />
    </div>
  );
};

export default Company;
