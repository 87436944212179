import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { popupHide } from './popupSlice';

const PerInfoPopup = () => {
  const popup = useSelector((state) => state.popup);

  const dispatch = useAppDispatch();

  const onClickPopupClose = () => {
    dispatch(popupHide());
  };

  return (
    <div
      className={popup.infoMode ? 'popup_wrap active' : 'popup_wrap'}
      data-popcont="PerInfoPopup"
    >
      <div className="dim" onClick={onClickPopupClose}></div>
      <div className="inner">
        {/* title */}
        <div className="pop_header">
          <strong className="tlt">개인정보처리방침</strong>
        </div>
        {/* content */}
        <div className="pop_content">
          <div className="box-privite box-scroll">
            <p>
              '주식회사 인스플래닛'(이하 '회사'라 합니다)는 「개인정보 보호법」
              제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을
              신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
              개인정보 처리방침을 수립·공개합니다.
            </p>
            <p>이 개인정보처리방침은 2022년 12월 15부터 적용됩니다.</p>
            <h1>
              <strong>제 1조 개인정보의 처리 목적</strong>
            </h1>
            <p>
              회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
              개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이
              변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를
              받는 등 필요한 조치를 이행할 예정입니다.
            </p>
            <table className="table-type1">
              <caption className="blind">수집 목적 및 항목, 기간</caption>
              <colgroup>
                <col width="24%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>수집 목적</th>
                  <td>프로젝트 의뢰 및 인사지원에 대한 응대</td>
                </tr>
                <tr>
                  <th>수집 항목</th>
                  <td>
                    <ul className="list-type1">
                      <li>프로젝트 의뢰 : 성명, 이메일, 연락처, 회사명</li>
                      <li>입사지원 : 성명, 이메일, 연락처</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>보유 기간</th>
                  <td>
                    <strong>접수 후 1년간 보유</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="pop_footer">
          <button
            type="button"
            className="btn_m_line"
            onClick={onClickPopupClose}
            data-pop-confirm
          >
            확인
          </button>
        </div>
        <button
          type="button"
          title="팝업 닫기"
          className="btn_close"
          onClick={onClickPopupClose}
        ></button>
      </div>
    </div>
  );
};

export default PerInfoPopup;
