import React from 'react';
// component
import Intro from './Intro';
import Project from './Project';

const Main = () => {
  return (
    <div className="main scroll">
      <Intro />
      <Project />
    </div>
  );
};

export default Main;
