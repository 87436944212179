import React from 'react';

function NoAccess() {
  return (
    <div className="contain-page no_access">
      <p className="text-information-head">접속이 원할하지 않습니다.</p>
      <p className="text-information-body">
        일시적인 오류로 인하여 시스템 접속이 원활하지 않습니다.
        <br />
        잠시후 다시 접속해주세요.
      </p>
      <a href="/" className="link-home" title="메인페이지로 이동">
        인스플래닛 홈 가기
      </a>
    </div>
  );
}

export default NoAccess;
