import { disableBodyScroll } from 'body-scroll-lock';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store';
import Loading from '../../common/Loading';
import { showCollect, showUse } from '../../common/popupSlice';
import { openModal } from './alertSlice';
import { postJoins, postProjects } from './contactItemSlice';

const FilterItem = () => {
    function directInput() {
        const input = document.querySelectorAll(".direct_wrap input[type='radio']");

        input.forEach(e => {
            e.addEventListener('change', input => {
                const inputTxt = input.target.closest('.input_item').nextSibling;
                if (input.target.dataset.direct) {
                    inputTxt.classList.add('active');
                } else {
                    inputTxt.classList.remove('active');
                }
            });
        });
    }
    directInput();

    const dispatch = useAppDispatch();
    const contactList = useSelector(state => state.contactList);
    const contactItem = useSelector(state => state.contact);

    const [projectField, setProjectField] = useState({
        companyVal: '',
        nameVal: '',
        numberVal: '',
        emailVal: '',
        fieldVal: '',
        optionVal: '',
        readOnlyVal: false,
        optionTxtVal: '',
        additionVal: '',
        refVal: null,
        agrcont1: '',
        agrcont2: '',
        isShowCompany: false,
        isShowName: false,
        isShowNumber: false,
        isShowEmail: false,
    });

    const [joinField, setJoinField] = useState({
        nameVal: '',
        numberVal: '',
        emailVal: '',
        fieldVal: '',
        portfolioUrlVal: '',
        resumeFile: null,
        additionVal: '',
        agrcont1: '',
        agrcont2: '',
        isShowName: false,
        isShowNumber: false,
        isShowEmail: false,
    });

    const onChangeProjectCompany = e => {
        setProjectField({
            ...projectField,
            companyVal: e.target.value,
            isShowCompany: false,
        });
    };

    const onChangeProjectName = e => {
        setProjectField({
            ...projectField,
            nameVal: e.target.value,
            isShowName: false,
        });
    };

    const onChangeProjectNumber = e => {
        setProjectField({
            ...projectField,
            numberVal: e.target.value,
            isShowNumber: false,
        });
    };

    const onChangeProjectEmail = e => {
        setProjectField({
            ...projectField,
            emailVal: e.target.value,
            isShowEmail: false,
        });
    };

    const onChangeProjectOptionTxt = e => {
        setProjectField({ ...projectField, optionTxtVal: e.target.value });
    };

    const onChangeProjectAddition = e => {
        setProjectField({ ...projectField, additionVal: e.target.value });
    };

    const onClickProjectAgr = () => {
        let PerUse = {};
        let PerCollect = {};

        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let date = d.getDate();
        let hour = d.getHours();
        let minute = d.getMinutes();
        let second = d.getSeconds();
        let dateData = `${year}-${('0' + month).slice(-2)}-${('0' + date).slice(-2)}(${(
            '0' + hour
        ).slice(-2)}:${('0' + minute).slice(-2)}:${('0' + second).slice(-2)})`;

        PerUse =
            document.getElementById('PerUseHeader').innerHTML +
            document.getElementById('PerUseContent').innerHTML +
            '동의 일시' +
            '\u00A0' +
            '|' +
            '\u00A0' +
            dateData;
        PerCollect =
            document.getElementById('PerCollectHeader').innerHTML +
            document.getElementById('PerCollectContent').innerHTML +
            '동의 일시' +
            '\u00A0' +
            '|' +
            '\u00A0' +
            dateData;

        setProjectField({
            ...projectField,
            agrcont1: PerUse,
            agrcont2: PerCollect,
        });
    };

    const onClickUsepopup = () => {
        dispatch(showUse());
    };

    const onClickcollectpopup = () => {
        dispatch(showCollect());
    };

    const onChangeJoinName = e => {
        setJoinField({ ...joinField, nameVal: e.target.value, isShowName: false });
    };

    const onChangeJoinNumber = e => {
        setJoinField({
            ...joinField,
            numberVal: e.target.value,
            isShowNumber: false,
        });
    };

    const onChangeJoinEmail = e => {
        setJoinField({
            ...joinField,
            emailVal: e.target.value,
            isShowEmail: false,
        });
    };

    const onChangeJoinUrl = e => {
        setJoinField({ ...joinField, portfolioUrlVal: e.target.value });
    };

    const onChangeJoinAddition = e => {
        setJoinField({ ...joinField, additionVal: e.target.value });
    };

    const onClickJoinAgr = () => {
        let PerUse = {};
        let PerCollect = {};
        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let date = d.getDate();
        let hour = d.getHours();
        let minute = d.getMinutes();
        let second = d.getSeconds();
        let dateData = `${('0' + year).slice(-2)}-${('0' + month).slice(-2)}-${('0' + date).slice(
            -2,
        )}(${('0' + hour).slice(-2)}:${('0' + minute).slice(-2)}:${('0' + second).slice(-2)})`;

        PerUse =
            document.getElementById('PerUseHeader').innerHTML +
            document.getElementById('PerUseContent').innerHTML +
            '동의 일시' +
            '\u00A0' +
            '|' +
            '\u00A0' +
            dateData;
        PerCollect =
            document.getElementById('PerCollectHeader').innerHTML +
            document.getElementById('PerCollectContent').innerHTML +
            '동의 일시' +
            '\u00A0' +
            '|' +
            '\u00A0' +
            dateData;

        setJoinField({ ...joinField, agrcont1: PerUse, agrcont2: PerCollect });
    };

    let projectFormData = {
        companyNm: projectField.companyVal,
        inqerNm: projectField.nameVal,
        inqerPhoneNo: projectField.numberVal,
        inqerEmailAddr: projectField.emailVal,
        prjCateCd: projectField.fieldVal,
        prjOpenSchlCd: projectField.optionVal,
        prjOpenSchlInputCont: projectField.optionTxtVal,
        inqCont: projectField.additionVal,
        file: projectField.refVal,
        prsInfoCltAgrCont: projectField.agrcont1,
        prsInfo3cProAgrCont: projectField.agrcont2,
    };

    let joinFormData = {
        inqerNm: joinField.nameVal,
        inqerPhoneNo: joinField.numberVal,
        inqerEmailAddr: joinField.emailVal,
        joinSupFidCd: joinField.fieldVal,
        ptfUrlAddr: joinField.portfolioUrlVal,
        file: joinField.resumeFile,
        inqCont: joinField.additionVal,
        prsInfoCltAgrCont: joinField.agrcont1,
        prsInfo3cProAgrCont: joinField.agrcont2,
    };

    function projectData() {
        return new Promise(resolve => {
            let data = dispatch(postProjects(projectFormData));
            resolve(data);
        });
    }

    const onSubmitProject = () => {
        const {
            companyVal,
            nameVal,
            numberVal,
            emailVal,
            fieldVal,
            optionVal,
            optionTxtVal,
            additionVal,
            agrcont1,
            agrcont2,
        } = projectField;
        const regExpNumber =
            /^((01[0|6|7|8|9])|(0[2|31|32|33|41|41|43|44|51|52|53|54|55|61|62|63|64]))+\d{3,4}\d{4}$/;
        const regExpEmail =
            /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;

        let nemberTest = regExpNumber.test(numberVal);
        let emailTest = regExpEmail.test(emailVal);

        if (
            companyVal === '' ||
            nameVal === '' ||
            numberVal === '' ||
            nemberTest === false ||
            emailVal === '' ||
            emailTest === false ||
            fieldVal === '' ||
            optionVal === '' ||
            (optionVal === 'C1804' && optionTxtVal === '') ||
            additionVal === '' ||
            agrcont1 === '' ||
            agrcont2 === ''
        ) {
            if (companyVal === '') {
                setProjectField({
                    ...projectField,
                    isShowCompany: true,
                    isShowName: false,
                    isShowNumber: false,
                    isShowEmail: false,
                });
                document.getElementById('companyName').focus();
            } else if (nameVal === '') {
                setProjectField({
                    ...projectField,
                    isShowCompany: false,
                    isShowName: true,
                    isShowNumber: false,
                    isShowEmail: false,
                });
                document.getElementById('name').focus();
            } else if (numberVal === '') {
                setProjectField({
                    ...projectField,
                    isShowCompany: false,
                    isShowName: false,
                    isShowNumber: true,
                    isShowEmail: false,
                });
                document.getElementById('number').focus();
            } else if (nemberTest === false) {
                dispatch(
                    openModal({
                        modalTitle: '연락처 형식 오류',
                        modalType: '연락처 형식이 올바르지 않습니다. 확인 후 다시 입력하세요.',
                    }),
                );
                document.getElementById('number').focus();
            } else if (emailVal === '') {
                setProjectField({
                    ...projectField,
                    isShowCompany: false,
                    isShowName: false,
                    isShowNumber: false,
                    isShowEmail: true,
                });
                document.getElementById('email').focus();
            } else if (emailTest === false) {
                dispatch(
                    openModal({
                        modalTitle: '이메일 형식 오류',
                        modalType: '이메일 형식이 올바르지 않습니다. 확인 후 다시 입력하세요.',
                    }),
                );
                document.getElementById('email').focus();
            } else if (fieldVal === '') {
                dispatch(
                    openModal({
                        modalTitle: '프로젝트 유형',
                        modalType: '프로젝트 유형을 선택하세요.',
                    }),
                );
            } else if (optionVal === '') {
                dispatch(
                    openModal({
                        modalTitle: '프로젝트 오픈 일정',
                        modalType: '프로젝트 오픈 일정을 선택하세요.',
                    }),
                );
            } else if (optionVal === 'C1804' && optionTxtVal === '') {
                dispatch(
                    openModal({
                        modalTitle: '프로젝트 오픈 일정',
                        modalType: '프로젝트 오픈 일정을 입력하세요.',
                    }),
                );
            } else if (additionVal === '') {
                dispatch(
                    openModal({
                        modalTitle: '프로젝트 내용',
                        modalType: '프로젝트 내용을 입력하세요.',
                    }),
                );
            } else if (agrcont1 === '') {
                dispatch(
                    openModal({
                        modalTitle: '동의 안내',
                        modalType: '개인정보 수집∙이용 동의를 확인하세요.',
                    }),
                );
            } else if (agrcont2 === '') {
                dispatch(
                    openModal({
                        modalTitle: '동의 안내',
                        modalType: '개인정보 취급위탁에 대한 동의를 확인하세요.',
                    }),
                );
            }
        } else {
            projectData().then(resolvedData => {
                let result = resolvedData.type.endsWith('fulfilled');

                if (result) {
                    setProjectField({
                        ...projectField,
                        companyVal: '',
                        nameVal: '',
                        numberVal: '',
                        emailVal: '',
                        fieldVal: '',
                        optionVal: '',
                        readOnlyVal: false,
                        optionTxtVal: '',
                        additionVal: '',
                        refVal: null,
                        agrcont1: '',
                        agrcont2: '',
                        isShowCompany: false,
                        isShowName: false,
                        isShowNumber: false,
                        isShowEmail: false,
                    });
                    alert('프로젝트 의뢰가 접수되었습니다.\n담당자가 확인 후 연락드리겠습니다.');
                    window.location.replace('/contact');
                } else {
                    alert('오류가 발생하여 정상적으로 의뢰되지 않았습니다.');
                }
            });
        }
    };

    function joinData() {
        return new Promise(resolve => {
            resolve(dispatch(postJoins(joinFormData)));
        });
    }

    const onSubmitJoin = () => {
        const { nameVal, numberVal, emailVal, fieldVal, agrcont1, agrcont2 } = joinField;
        const regExpNumber =
            /^((01[0|6|7|8|9])|(0[2|31|32|33|41|41|43|44|51|52|53|54|55|61|62|63|64]))+\d{3,4}\d{4}$/;
        const regExpEmail =
            /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;

        let nemberTest = regExpNumber.test(numberVal);
        let emailTest = regExpEmail.test(emailVal);

        if (
            nameVal === '' ||
            numberVal === '' ||
            nemberTest === false ||
            emailVal === '' ||
            emailTest === false ||
            fieldVal === '' ||
            agrcont1 === '' ||
            agrcont2 === ''
        ) {
            if (nameVal === '') {
                setJoinField({
                    ...joinField,
                    isShowName: true,
                    isShowNumber: false,
                    isShowEmail: false,
                });
                document.getElementById('yourName').focus();
            } else if (numberVal === '') {
                setJoinField({
                    ...joinField,
                    isShowName: false,
                    isShowNumber: true,
                    isShowEmail: false,
                });
                document.getElementById('yourNumber').focus();
            } else if (nemberTest === false) {
                dispatch(
                    openModal({
                        modalTitle: '연락처 형식 오류',
                        modalType: '연락처 형식이 올바르지 않습니다. 확인 후 다시 입력하세요.',
                    }),
                );
                document.getElementById('yourNumber').focus();
            } else if (emailVal === '') {
                setJoinField({
                    ...joinField,
                    isShowName: false,
                    isShowNumber: false,
                    isShowEmail: true,
                });
                document.getElementById('yourEmail').focus();
            } else if (emailTest === false) {
                dispatch(
                    openModal({
                        modalTitle: '이메일 형식 오류',
                        modalType: '이메일 형식이 올바르지 않습니다. 확인 후 다시 입력하세요.',
                    }),
                );
                document.getElementById('yourEmail').focus();
            } else if (fieldVal === '') {
                dispatch(
                    openModal({
                        modalTitle: '지원 분야',
                        modalType: '지원 분야를 선택하세요.',
                    }),
                );
            } else if (agrcont1 === '') {
                dispatch(
                    openModal({
                        modalTitle: '동의 안내',
                        modalType: '개인정보 수집∙이용 동의를 확인하세요.',
                    }),
                );
            } else if (agrcont2 === '') {
                dispatch(
                    openModal({
                        modalTitle: '동의 안내',
                        modalType: '개인정보 취급위탁에 대한 동의를 확인하세요.',
                    }),
                );
            }
        } else {
            joinData().then(resolvedData => {
                let result = resolvedData.type.endsWith('fulfilled');

                if (result) {
                    setJoinField({
                        ...joinField,
                        nameVal: '',
                        numberVal: '',
                        emailVal: '',
                        fieldVal: '',
                        portfolioUrlVal: '',
                        resumeFile: null,
                        additionVal: '',
                        agrcont1: '',
                        agrcont2: '',
                        isShowName: false,
                        isShowNumber: false,
                        isShowEmail: false,
                    });

                    alert('입사지원이 완료되었습니다.\n담당자가 확인 후 연락드리겠습니다.');
                    window.location.replace('/contact');
                } else {
                    alert('오류가 발생하여 정상적으로 지원되지 않았습니다.');
                }
            });
        }
    };

    function labelOn(input) {
        const label = input.target.nextSibling;
        if (!input.target.value) {
            label.classList.add('move');
        }
    }

    function labelOff(input) {
        const label = input.target.nextSibling;
        if (!input.target.value) {
            label.classList.remove('move');
        }
    }

    const fileUpload = file => {
        let fileName = file.target.files[0].name;
        let fileSize = file.target.files[0].size;
        let nameDiv = file.target.nextSibling.nextSibling;
        // 용량제한 20MB
        const maxSize = 20 * 1024 * 1024;
        const alert = document.querySelector('[data-popcont="alert"]');

        if (file.target.value) {
            if (fileSize > maxSize) {
                // 용량 초과
                dispatch(
                    openModal({
                        modalTitle: '첨부파일 용량 초과 안내',
                        modalType: '첨부파일 용량은 최대 20MB 이하로 \n업로드 가능합니다.',
                    }),
                );

                file.target.value = '';
                alert.classList.add('active');
                disableBodyScroll(alert);
            }
            if (
                !fileName.endsWith('doc') &&
                !fileName.endsWith('docx') &&
                !fileName.endsWith('xlsx') &&
                !fileName.endsWith('xls') &&
                !fileName.endsWith('ppt') &&
                !fileName.endsWith('pptx') &&
                !fileName.endsWith('pdf') &&
                !fileName.endsWith('png') &&
                !fileName.endsWith('jpg') &&
                !fileName.endsWith('jpeg') &&
                !fileName.endsWith('zip')
            ) {
                dispatch(
                    openModal({
                        modalTitle: '첨부파일 확장자 안내',
                        modalType:
                            '업로드 가능한 파일 확장자는 \ndoc, docx, xlsx, xls, ppt, pptx, pdf,\npng, jpg, jpeg, zip 입니다',
                    }),
                );
            } else {
                // 안내 메세지 삭제
                const infoMsg = file.target.closest('.input_item').querySelector('.file_info');
                infoMsg.classList.add('off');

                nameDiv.innerText = fileName;
                setJoinField({ ...joinField, resumeFile: file.target.files[0] });
                setProjectField({ ...projectField, refVal: file.target.files[0] });
            }
        }
    };

    function infoTxtChange(e) {
        const selectTlt = e.target.dataset.contTlt;
        const infoAll = document.querySelectorAll('[data-cont-info]');

        infoAll.forEach(info => {
            if (info.dataset.contInfo === selectTlt) {
                info.classList.add('active');
            } else {
                info.classList.remove('active');
            }
        });
    }

    function agreeAll(check) {
        const agreeChild = check.target.closest('.input_item').querySelectorAll('.agree_item');
        if (check.target.checked) {
            agreeChild.forEach(child => {
                child.checked = true;
            });
        } else {
            agreeChild.forEach(child => {
                child.checked = false;
            });
        }
    }

    function agreeItem(item) {
        const agreeAll = item.target.closest('.input_item').querySelector('.agree_all');
        const slibing = item.target.closest('.sub_input_item').querySelectorAll('.agree_item');

        if (!item.target.checked) {
            agreeAll.checked = false;
        } else {
            slibing.forEach(agree => {
                if (agree.checked) {
                    agreeAll.checked = true;
                } else {
                    agreeAll.checked = false;
                }
            });
        }
    }
    const optionSelect = option => {
        // option.preventDefault();

        let optionValue = option.target.value;

        if (optionValue !== 'C1804') {
            setProjectField({
                ...projectField,
                optionVal: optionValue,
                readOnlyVal: true,
            });

            option.target.closest('.input_item').nextSibling.classList.remove('active');
        } else if (optionValue === 'C1804') {
            setProjectField({
                ...projectField,
                optionVal: optionValue,
                readOnlyVal: false,
            });

            option.target.closest('.input_item').nextSibling.classList.add('active');
        }
    };

    return (
        <div className="right">
            {/* 프로젝트 의뢰 */}
            {contactItem.loading ? (
                <Loading />
            ) : (
                <div
                    className={contactList.ListOne ? 'form_wrap active' : 'form_wrap'}
                    data-item="prjRequest"
                >
                    <ul className="input_cont">
                        <li>
                            <strong className="tlt require">
                                우리와 함께할 당신을 <br />
                                소개해주세요.
                            </strong>
                            {/* 에러메세지 표기시 error 클래스 추가 */}
                            <div
                                className={
                                    projectField.isShowCompany ? 'input_item error' : 'input_item'
                                }
                            >
                                <input
                                    id="companyName"
                                    type="text"
                                    className="input_txt"
                                    onFocus={labelOn}
                                    onBlur={labelOff}
                                    value={projectField.companyVal}
                                    onChange={onChangeProjectCompany}
                                />
                                <label htmlFor="companyName">회사명</label>
                                {projectField.isShowCompany && (
                                    <span className="err_msg">회사명을 입력하세요.</span>
                                )}
                            </div>
                            <div
                                className={
                                    projectField.isShowName ? 'input_item error' : 'input_item'
                                }
                            >
                                <input
                                    id="name"
                                    type="text"
                                    className="input_txt"
                                    onFocus={labelOn}
                                    onBlur={labelOff}
                                    value={projectField.nameVal}
                                    onChange={onChangeProjectName}
                                />
                                <label htmlFor="name">담당자명</label>
                                {projectField.isShowName && (
                                    <span className="err_msg">담당자명을 입력하세요.</span>
                                )}
                            </div>
                            <div
                                className={
                                    projectField.isShowNumber ? 'input_item error' : 'input_item'
                                }
                            >
                                <input
                                    id="number"
                                    type="text"
                                    className="input_txt"
                                    onFocus={labelOn}
                                    onBlur={labelOff}
                                    value={projectField.numberVal}
                                    onChange={onChangeProjectNumber}
                                />
                                <label htmlFor="number">담당자연락처(-빼고 입력해주세요)</label>
                                {projectField.isShowNumber && (
                                    <span className="err_msg">담당자 연락처를 입력하세요.</span>
                                )}
                            </div>
                            <div
                                className={
                                    projectField.isShowEmail ? 'input_item error' : 'input_item'
                                }
                            >
                                <input
                                    id="email"
                                    type="text"
                                    className="input_txt"
                                    onFocus={labelOn}
                                    onBlur={labelOff}
                                    value={projectField.emailVal}
                                    onChange={onChangeProjectEmail}
                                />
                                <label htmlFor="email">담당자 이메일</label>
                                {projectField.isShowEmail && (
                                    <span className="err_msg">담당자 이메일을 입력하세요.</span>
                                )}
                            </div>
                        </li>
                        <li>
                            <strong className="tlt require" id="switchTit">
                                우리와 함께 할 프로젝트 유형을 <br />
                                선택해 주세요.
                            </strong>
                            <div className="input_item switch">
                                <input
                                    id="uxUi"
                                    name="projectType"
                                    type="radio"
                                    className="input_switch"
                                    value="C1701"
                                    onChange={e =>
                                        setProjectField({
                                            ...projectField,
                                            fieldVal: e.target.value,
                                        })
                                    }
                                />
                                <label htmlFor="uxUi">UX・UI 컨설팅</label>
                                <input
                                    id="siteApp"
                                    name="projectType"
                                    type="radio"
                                    className="input_switch"
                                    value="C1702"
                                    onChange={e =>
                                        setProjectField({
                                            ...projectField,
                                            fieldVal: e.target.value,
                                        })
                                    }
                                />
                                <label htmlFor="siteApp">사이트 및 앱 구축</label>
                                <input
                                    id="admin"
                                    name="projectType"
                                    type="radio"
                                    className="input_switch"
                                    value="C1703"
                                    onChange={e =>
                                        setProjectField({
                                            ...projectField,
                                            fieldVal: e.target.value,
                                        })
                                    }
                                />
                                <label htmlFor="admin">유지보수 / 운영</label>
                                <input
                                    id="etc"
                                    name="projectType"
                                    type="radio"
                                    className="input_switch"
                                    value="C1704"
                                    onChange={e =>
                                        setProjectField({
                                            ...projectField,
                                            fieldVal: e.target.value,
                                        })
                                    }
                                />
                                <label htmlFor="etc">기타</label>
                            </div>
                        </li>
                        <li className="layout">
                            <strong className="tlt require">
                                우리와 함께 할 프로젝트의 <br />
                                오픈일정을 알려주세요.
                            </strong>
                            <div className="input_item switch">
                                <input
                                    id="early"
                                    name="openDate"
                                    type="radio"
                                    value="C1801"
                                    className="input_switch"
                                    onChange={optionSelect}
                                />
                                <label htmlFor="early">최대한 빠르게 오픈</label>
                                <input
                                    id="2to3"
                                    name="openDate"
                                    type="radio"
                                    value="C1802"
                                    className="input_switch"
                                    onChange={optionSelect}
                                />
                                <label htmlFor="2to3">2~3개월 내 오픈</label>
                                <input
                                    id="4to6"
                                    name="openDate"
                                    type="radio"
                                    value="C1803"
                                    className="input_switch"
                                    onChange={optionSelect}
                                />
                                <label htmlFor="4to6">4~6개월 내 오픈</label>
                                <input
                                    id="dateEtc"
                                    name="openDate"
                                    type="radio"
                                    value="C1804"
                                    className="input_switch"
                                    onChange={optionSelect}
                                />
                                <label htmlFor="dateEtc">직접입력</label>
                            </div>
                            <div className="input_item">
                                <input
                                    id="dateEtcDiv"
                                    type="text"
                                    className="input_txt_layout"
                                    placeholder="직접입력"
                                    value={projectField.optionTxtVal}
                                    onChange={onChangeProjectOptionTxt}
                                    readOnly={projectField.readOnlyVal}
                                />
                            </div>
                        </li>
                        <li>
                            <strong className="tlt require">
                                우리와 함께 할 프로젝트에 대해 <br />
                                자세히 알려주세요.
                            </strong>
                            <div className="input_item">
                                <textarea
                                    name="projectDetail"
                                    id="projectDetail"
                                    placeholder="선호하는 레퍼런스, 프로젝트 목적, 웹사이트 방향성, 문의 내용 등을 자세히 알려주세요.&#13;&#10;예시) 프로젝트 제목 : 인스플래닛 웹사이트 리뉴얼 참고사이트 URL : insplanet.co.kr 문의 내용 : 리뉴얼 관련하여 참고 URL를 첨부하오니 확인 부탁드립니다."
                                    value={projectField.additionVal}
                                    onChange={onChangeProjectAddition}
                                ></textarea>
                                <input
                                    id="repFile"
                                    type="file"
                                    name="repFile"
                                    className="input_file"
                                    onChange={fileUpload}
                                />
                                <label htmlFor="repFile" className="btn_m_primary">
                                    RFP 및 참고문서 (선택) <i className="ico_15_upload"></i>
                                </label>
                                <span className="file_name"></span>
                                <span className="file_info">
                                    최대 20MB 이하 / 2개이상 파일은 압축하여 zip파일로 올려주세요.
                                </span>
                            </div>
                            <div className="input_item">
                                <label className="input_checkbox_wrap">
                                    <input
                                        type="checkbox"
                                        name="agree_wrap"
                                        className="agree_all"
                                        onChange={agreeAll}
                                        onClick={onClickProjectAgr}
                                    />
                                    개인정보 수집 이용, 개인정보 취급위탁에 모두 동의합니다.
                                </label>
                                <ul className="sub_input_item">
                                    <li>
                                        <label className="input_checkbox">
                                            <input
                                                type="checkbox"
                                                className="agree_item"
                                                name="agree_item"
                                                onChange={agreeItem}
                                                onClick={onClickProjectAgr}
                                            />
                                            <span className="tlt require">
                                                개인정보 수집∙이용 동의
                                            </span>
                                            <button type="button" onClick={onClickUsepopup}>
                                                <i className="ico_16_next"></i>
                                            </button>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="input_checkbox">
                                            <input
                                                type="checkbox"
                                                className="agree_item"
                                                name="agree_item"
                                                onChange={agreeItem}
                                                onClick={onClickProjectAgr}
                                            />
                                            <span className="tlt require">
                                                개인정보 취급위탁에 대한 동의
                                            </span>
                                            <button type="button" onClick={onClickcollectpopup}>
                                                <i className="ico_16_next"></i>
                                            </button>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <div className="btn_wrap">
                        <button type="submit" className="btn_l_second" onClick={onSubmitProject}>
                            의뢰하기
                        </button>
                    </div>
                </div>
            )}
            {/* 입사지원 */}
            {contactItem.loading ? (
                <Loading />
            ) : (
                <div
                    className={contactList.ListTwo ? 'form_wrap active' : 'form_wrap'}
                    data-item="workRequest"
                >
                    <ul className="input_cont">
                        <li>
                            <strong className="tlt require">
                                우리와 함께할 당신을 <br />
                                소개해주세요.
                            </strong>
                            <div
                                className={joinField.isShowName ? 'input_item error' : 'input_item'}
                            >
                                <input
                                    id="yourName"
                                    type="text"
                                    className="input_txt"
                                    onFocus={labelOn}
                                    onBlur={labelOff}
                                    value={joinField.nameVal}
                                    onChange={onChangeJoinName}
                                />
                                <label htmlFor="yourName">이름</label>
                                {joinField.isShowName && (
                                    <span className="err_msg">이름을 입력하세요.</span>
                                )}
                            </div>
                            <div
                                className={
                                    joinField.isShowNumber ? 'input_item error' : 'input_item'
                                }
                            >
                                <input
                                    id="yourNumber"
                                    type="text"
                                    className="input_txt"
                                    onFocus={labelOn}
                                    onBlur={labelOff}
                                    value={joinField.numberVal}
                                    onChange={onChangeJoinNumber}
                                />
                                <label htmlFor="yourNumber">연락처(-빼고 입력해주세요)</label>
                                {joinField.isShowNumber && (
                                    <span className="err_msg">연락처를 입력하세요.</span>
                                )}
                            </div>
                            <div
                                className={
                                    joinField.isShowEmail ? 'input_item error' : 'input_item'
                                }
                            >
                                <input
                                    id="yourEmail"
                                    type="text"
                                    className="input_txt"
                                    onFocus={labelOn}
                                    onBlur={labelOff}
                                    value={joinField.emailVal}
                                    onChange={onChangeJoinEmail}
                                />
                                <label htmlFor="yourEmail">이메일</label>
                                {joinField.isShowEmail && (
                                    <span className="err_msg">이메일을 입력하세요.</span>
                                )}
                            </div>
                        </li>
                        <li>
                            <strong className="tlt require">
                                자신있는 분야를 <br />
                                선택해주세요.
                            </strong>
                            <div className="input_item switch third">
                                <input
                                    id="planning"
                                    name="partSelect"
                                    type="radio"
                                    className="input_switch"
                                    onChange={() =>
                                        setJoinField({ ...joinField, fieldVal: 'C1902' })
                                    }
                                    onClick={infoTxtChange}
                                    data-cont-tlt="planning"
                                />
                                <label htmlFor="planning">기획</label>
                                <input
                                    id="design"
                                    name="partSelect"
                                    type="radio"
                                    className="input_switch"
                                    onChange={() =>
                                        setJoinField({ ...joinField, fieldVal: 'C1902' })
                                    }
                                    onClick={infoTxtChange}
                                    data-cont-tlt="design"
                                />
                                <label htmlFor="design">디자인</label>
                                <input
                                    id="frontend"
                                    name="partSelect"
                                    type="radio"
                                    className="input_switch"
                                    onChange={() =>
                                        setJoinField({ ...joinField, fieldVal: 'C1903' })
                                    }
                                    onClick={infoTxtChange}
                                    data-cont-tlt="frontend"
                                />
                                <label htmlFor="frontend">퍼블 ∙ 프론트엔드</label>
                            </div>
                            <ul className="info_type1">
                                <li>
                                    <strong className="name">담당업무</strong>
                                    <ul className="desc active" data-cont-info="planning">
                                        <li>∙ 분석(서비스,시장), 벤치마킹 등을 통한 시사점 도출</li>
                                        <li>∙ 서비스의 요구사항, 정책 등에 따른 분석/설계</li>
                                        <li>∙ UI/UX에 대한 이해를 바탕으로 한 Web/App 기획</li>
                                    </ul>
                                    <ul className="desc" data-cont-info="design">
                                        <li>∙ UI/UX 디자인</li>
                                        <li>∙ PC웹/모바일웹/앱 디자인</li>
                                    </ul>
                                    <ul className="desc" data-cont-info="frontend">
                                        <li>∙ 웹표준, 웹접근성, 크로스브라우징</li>
                                        <li>∙ 반응형, 적응형, 하이브리드 웹페이지 구현</li>
                                        <li>∙ 모바일웹 퍼블리싱 / jQuery</li>
                                        <li>∙ JavaScript, HTML5, CSS3, jQuery 이해 및 구현</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong className="name">자격요건</strong>
                                    <ul className="desc active" data-cont-info="planning">
                                        <li>∙ UI/UX 트렌드와 Mobile Device특성에 대한 이해</li>
                                        <li>
                                            ∙ 기획업무에 필요한 설득력 있는 Paper Work (화면설계서
                                            등 기획업무에 필요한 각 문서)
                                        </li>
                                    </ul>
                                    <ul className="desc" data-cont-info="design">
                                        <li>∙ 창의적인 사고와 감각적인 디자인을 추구하는 분</li>
                                        <li>∙ 고객 중심 사용성을 고려한 UI를 추구하는 분</li>
                                        <li>
                                            ∙ Web환경과 자인 가이드라인 작업에 대한 이해도가 높으신
                                            분
                                        </li>
                                        <li>
                                            ∙ 프로젝트 전체를 이해하고 문제 해결에 흥미를 느끼는 분
                                        </li>
                                        <li>
                                            ∙ 다양한 디자인 Tool 사용 가능한 분 (Figma / Sketch / XD
                                            / Zeplin / After Effects / Photoshop 등)
                                        </li>
                                    </ul>
                                    <ul className="desc" data-cont-info="frontend">
                                        <li>
                                            ∙ 웹표준 및 웹접근성, 시멘티 마크업에 대한 이해가 있으신
                                            분
                                        </li>
                                        <li>
                                            ∙ 크로스 브라징 및 모바일 자이스에 대한 이해가 있으신 분
                                        </li>
                                        <li>∙ Git에 대한 이해와 사용능력이 있으신 분</li>
                                        <li>∙ HTML5, CSS3, 반응형 웹 가능하신 분</li>
                                        <li>∙ 전문학사 이상 / 경력 6년 이상</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong className="tlt">
                                직무관련 및 입사지원 서류를 <br />
                                첨부해 주세요.
                            </strong>
                            <div className="input_item">
                                <input
                                    id="portfolioURL"
                                    type="text"
                                    className="input_txt"
                                    onFocus={labelOn}
                                    onBlur={labelOff}
                                    value={joinField.portfolioUrlVal}
                                    onChange={onChangeJoinUrl}
                                />
                                <label htmlFor="portfolioURL">포트폴리오 URL (선택)</label>
                            </div>
                            <div className="input_item file_wrap">
                                <input
                                    id="etcFile"
                                    type="file"
                                    name="etcFile"
                                    accept=".doc, .docx, .xlsx, .xls, .ppt, .pptx, .pdf, .png, .jpg, .jpeg, .zip"
                                    className="input_file"
                                    onChange={fileUpload}
                                />
                                <label htmlFor="etcFile" className="btn_m_primary">
                                    이력서 및 기타파일 <i className="ico_15_upload"></i>
                                </label>
                                <span className="file_name"></span>
                                <span className="file_info">
                                    최대 20MB 이하 / 2개이상 파일은 압축하여 zip파일로 올려주세요.
                                </span>
                            </div>
                        </li>
                        <li>
                            <strong className="tlt">
                                추가하고 싶은 내용이나 <br />
                                문의할 부분이 있으면 적어주세요.
                            </strong>
                            <div className="input_item">
                                <textarea
                                    name="projectDetail"
                                    id="projectDetail"
                                    placeholder="지원 동기, 비전 등 추가하고 싶은 내용 또는 궁금한 점이 있으면 적어주세요."
                                    value={joinField.additionVal}
                                    onChange={onChangeJoinAddition}
                                ></textarea>
                            </div>
                            <div className="input_item">
                                <label className="input_checkbox_wrap">
                                    <input
                                        type="checkbox"
                                        name="agree_wrap"
                                        className="agree_all"
                                        onChange={agreeAll}
                                        onClick={onClickJoinAgr}
                                    />
                                    개인정보 수집 이용, 개인정보 취급위탁에 모두 동의합니다.
                                </label>
                                <ul className="sub_input_item">
                                    <li>
                                        <label className="input_checkbox">
                                            <input
                                                type="checkbox"
                                                className="agree_item"
                                                name="agree_item"
                                                onChange={agreeItem}
                                                onClick={onClickJoinAgr}
                                            />
                                            <span className="tlt require">
                                                개인정보 수집∙이용 동의
                                            </span>
                                            <button type="button" onClick={onClickUsepopup}>
                                                <i className="ico_16_next"></i>
                                            </button>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="input_checkbox">
                                            <input
                                                type="checkbox"
                                                className="agree_item"
                                                name="agree_item"
                                                onChange={agreeItem}
                                                onClick={onClickJoinAgr}
                                            />
                                            <span className="tlt require">
                                                개인정보 취급위탁에 대한 동의
                                            </span>
                                            <button type="button" onClick={onClickcollectpopup}>
                                                <i className="ico_16_next"></i>
                                            </button>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <div className="btn_wrap">
                        <button type="button" className="btn_l_second" onClick={onSubmitJoin}>
                            지원하기
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterItem;
