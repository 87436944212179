import React from 'react';

const With = () => {
  const copyAddress = () => {
    const copyTxt = document.querySelector('.copy_address').innerText;
    window.navigator.clipboard.writeText(copyTxt).then(() => {});
  };
  const copyEmail = () => {
    const copyTxt = document.querySelector('.copy_email').innerText;
    window.navigator.clipboard.writeText(copyTxt).then(() => {});
  };

  return (
    <div className="bg_box2">
      <section className="content with" data-action="reveal-content">
        <div className="txt_wrap">
          <h2 className="tlt">
            <span className="top">WITH</span>
            <span className="btm">
              인스플래닛이 함께 <br />
              고민하겠습니다.
            </span>
          </h2>
          <p className="desc">프로젝트를 계획중이시면 언제든 문의 하세요.</p>
        </div>
        <ul className="info_wrap">
          <li className="copy_address">
            서울특별시 중구 퇴계로27길 28, <br />
            한영빌딩 601호
            <button type="button" title="주소 복사" onClick={copyAddress}>
              <i className="ico_20_copy bk"></i>
            </button>
          </li>
          <li>
            <a href="tel:02-2088-5084" title="전화걸기" className="info_item">
              02-2088-5084
            </a>{' '}
            (Fax 02-2088-5184)
          </li>
          <li>
            <a
              href="mailto:hello@insplanet.co.kr"
              title="메일 보내기"
              className="copy_email info_item"
            >
              hello@insplanet.co.kr
            </a>
            <button type="button" title="이메일 복사" onClick={copyEmail}>
              <i className="ico_20_copy bk"></i>
            </button>
          </li>
          <li>
            <a href="/contact" className="btn_l_second">
              CONTACT US
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default With;
