import React from "react";

function CopyRight() {
  return (
    <ul className="cont_copyright">
      <li>&copy; 2022 Ins Planet Inc.</li>
    </ul>
  );
}

export default CopyRight;
