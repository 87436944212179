import React, { useEffect } from 'react';
import { indicateShow } from '../../assets/js/indicate.js';

const ScrollAni = () => {
  useEffect(() => {
    let scrollPosition = window.pageYOffset;
    let scrollPrev = 0;

    window.addEventListener('scroll', function () {
      if (window.innerWidth < 720) {
        scrollPosition = window.pageYOffset;
        indicateShow(scrollPosition, scrollPrev);
        scrollPrev = scrollPosition;
      }
    });
  });
  return (
    <div className="scroll_ani">
      <span className="dot"></span>
      <span className="line"></span>
      <span className="dot_disabled"></span>
      <span className="dot_line"></span>
    </div>
  );
};

export default ScrollAni;
