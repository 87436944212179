import React, { useCallback } from 'react';
import { enableBodyScroll } from 'body-scroll-lock';
import { Link } from 'react-router-dom';

const InnerNav = () => {
  window.addEventListener('resize', () => {
    const inner = document.querySelector('.nav_inner');
    if (window.innerWidth >= 720 && inner.classList.contains('active')) {
      inner.classList.remove('active');
      enableBodyScroll(inner);
    }
  });

  const gnbClose = useCallback((e) => {
    const inner = e.target.closest('.nav_inner');
    inner.classList.remove('active');
    enableBodyScroll(inner);

    const menu = document.querySelectorAll('.menu');
    menu.forEach((m) => {
      if (m.classList.contains('active')) {
        m.classList.remove('active');
      }
    });

    e.target.classList.add('active');
  }, []);

  return (
    <div className="nav_inner">
      <ul>
        <li>
          <Link
            to="/project"
            title="페이지 이동"
            className="menu"
            onClick={gnbClose}
          >
            PROJECT
          </Link>
        </li>
        <li>
          <Link
            to="/company"
            title="페이지 이동"
            className="menu"
            onClick={gnbClose}
          >
            COMPANY
          </Link>
        </li>
        <li>
          <Link
            to="/culture"
            title="페이지 이동"
            className="menu"
            onClick={gnbClose}
          >
            CULTURE
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            title="페이지 이동"
            className="menu"
            onClick={gnbClose}
          >
            CONTACT
          </Link>
        </li>
      </ul>
      <button
        type="button"
        className="btn_gnb_close"
        title="메뉴 닫기"
        onClick={gnbClose}
      >
        <i className="ico_32_gnbClose"></i>
      </button>
      <span className="copy_right">&copy; 2022 INS PLANET INC.</span>
    </div>
  );
};

export default InnerNav;
