import React from 'react';
import CopyRight from './CopyRight';
import Info from './Info';
import Link from './Link';

function Footer() {
    if (window.location.pathname === '/noAccess') return null;
    return (
        <footer>
            <Info />
            <Link />
            <CopyRight />
        </footer>
    );
}

export default Footer;
