import React from 'react';
import { useAppDispatch } from '../../store';
import { shiowInfo } from '../common/popupSlice';

function Link() {

  const dispatch = useAppDispatch();

  const onClickInfopopup = () => {
    dispatch(shiowInfo());
}
  return (
    <ul className="cont_link">
      <li>
        <a href="/notice" title="페이지 열기" className="notice">
          공지사항
        </a>
      </li>
      <li>
        <button
          type="button"
          title="페이지 열기"
          className="privacy"
          onClick={onClickInfopopup}
        >
          개인정보처리방침
        </button>
      </li>
      <li>
        <a
          href="https://www.instagram.com/insplanet_official/"
          target="/blank"
          title="페이지 열기"
          className="instagram"
        >
          INSTAGRAM
        </a>
      </li>
    </ul>
  );
}

export default Link;
