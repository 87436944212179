import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { listTwoFn } from '../contact/contactListSlice';

const BtnWrap = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onClickJoin = () => {
        navigate('/contact');
        dispatch(listTwoFn());
    };

    return (
        <div className="btn_wrap">
            <a
                href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/insplanet"
                target="_blank"
                title="페이지 이동"
                className="btn_l_line"
                style={{ textAlign: 'center' }}
            >
                진행중인 채용공고
                <i className="ico_24_link"></i>
            </a>
            <button title="페이지 이동" className="btn_l_primary" onClick={onClickJoin}>
                입사 지원
                <i className="ico_20_next"></i>
            </button>
        </div>
    );
};

export default BtnWrap;
