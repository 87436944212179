import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ListOne: true, 
    ListTwo: false, 
    tabIndexOne: 0, 
    tabIndexTwo: -1
  };
  
export const contactListSlice = createSlice({
  name: "contactList",
  initialState,
  reducers: {
    listOneFn: (state) => {
        state.ListOne = true;
        state.ListTwo = false; 
        state.tabIndexOne = 0; 
        state.tabIndexTwo = -1;
    },
    listTwoFn: (state) => {
        state.ListOne = false;
        state.ListTwo = true;
        state.tabIndexOne = -1; 
        state.tabIndexTwo = 0;
    },
  },
});

export const { listOneFn, listTwoFn } = contactListSlice.actions;
export const selectList = (state) => state.contactList;

export default contactListSlice.reducer;