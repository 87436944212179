import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import requests from '../../../api/requests';
import axios from '../../../api/axios';

const initialState = {
    cateList: [],
    list: [],
    totalPageCount: '',
    isAdd: false,
};

export const getProject = createAsyncThunk('project/getProject', async reqParams => {
    if (reqParams) {
        const projectData = await axios.get(
            requests.PROJECT_LIST +
                '?srchPrjTagCd=' +
                reqParams.cateCode +
                '&pageIndex=' +
                reqParams.pageIndex,
        );
        let projectListData = projectData.data.data;
        let project = { cateList: [], ...projectListData };
        return project;
    } else {
        //초기렌더링
        const projectCate = await axios.get(requests.COMMON_GET_CATE + '?grpCd=C1500');
        const projectData = await axios.get(requests.PROJECT_LIST);

        let projectCateList = projectCate.data.data.list;
        let projectListData = projectData.data.data;

        let project = { cateList: projectCateList, ...projectListData };
        return project;
    }
});

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        actIsAdd: (state, { payload }) => {
            state.isAdd = payload;
        },
    },
    extraReducers: builder =>
        builder.addCase(getProject.fulfilled, (state, { payload }) => {
            state.cateList = payload.cateList.length ? [...payload.cateList] : [...state.cateList];
            state.list = state.isAdd ? [...state.list, ...payload.list] : payload.list;
            state.dataTotalCnt = payload.totalCount;
            state.totalPageCount = payload.pagination.totalPageCount;
        }),
});

export const { actIsAdd } = projectSlice.actions;
export default projectSlice;
