import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { baseUrl } from "../../../api/requests";
import { useAppDispatch, useAppSelector } from "../../../store";
import Loading from "../../common/Loading";
import { getMainProject } from "./mainSlice";

const Project = () => {
  const { list: mainProjectList, dataTotalCnt } = useAppSelector(
    (state) => state.main
  );
  const { loading } = useAppSelector((state) => state.loading);

  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMainProject());
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      gsap.registerPlugin(ScrollTrigger);

      const item = document.querySelectorAll(".item");
      const numElement = document.querySelector(".num");

      // let height = 0;

      // for (let i = 0; i < item.length; i++) {
      //   height = height + item[i].clientHeight;
      // }

      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".main_prj", // 기준 target
            pin: true, // 고정
            start: "top top",
            end: "+=" + 5000,
            scrub: 1, // 모션바운스
            //   snap: {
            //     ease: 'power1.inOut',
            //   },
          },
        })
        .to(item[0], {
          duration: 0.2,
          opacity: 0,
          y: -30,
          delay: 1,
          display: "none",
        })
        .to(item[1], {
          duration: 1.2,
          opacity: 1,
          y: -30,
          display: "block",
          onStart() {
            numElement.innerText = "02";
          },
          onReverseComplete() {
            numElement.innerText = "01";
          },
        })
        .to(item[1], { duration: 0.2, opacity: 0, delay: 1, display: "none" })
        .to(item[2], {
          duration: 1.2,
          opacity: 1,
          y: -30,
          display: "block",
          onStart() {
            numElement.innerText = "03";
          },
          onReverseComplete() {
            numElement.innerText = "02";
          },
        })
        .to(item[2], { duration: 0.2, opacity: 0, delay: 1, display: "none" })
        .to(item[3], {
          duration: 1.2,
          opacity: 1,
          y: -30,
          display: "block",
          onStart() {
            numElement.innerText = "04";
          },
          onReverseComplete() {
            numElement.innerText = "03";
          },
        })
        .to(item[3], { duration: 0.2, opacity: 0, delay: 1, display: "none" })
        .to(item[4], {
          duration: 1.2,
          opacity: 1,
          y: -30,
          display: "block",
          onStart() {
            numElement.innerText = "05";
          },
          onReverseComplete() {
            numElement.innerText = "04";
          },
        });
    }, 2000);
  }, []);

  return (
    <section className="content main_prj">
      {loading ? (
        <Loading />
      ) : (
        <div className="main_project_wrap">
          <div className="slide_num">
            <span className="num">01</span>
            <span className="total">
              /{dataTotalCnt < 10 ? "0" + dataTotalCnt : dataTotalCnt}
            </span>
          </div>
          <div className="item_wrap">
            {mainProjectList?.map((project) => (
              <Link
                to={
                  project.prjDetailDispYn === "Y"
                    ? "/project/detail/" + project.prjSeq
                    : "/"
                }
                state={{ prevPath: location.pathname }}
                key={project.prjSeq}
                className="item"
              >
                <span
                  role="button"
                  href="/project/detail"
                  className="main_project"
                  title="페이지 이동"
                >
                  {/* 비밀글의 경우 img_box secret 듀얼 클래스 추가 */}
                  <div
                    className={
                      project.prjDetailDispYn === "N"
                        ? "img_box secret"
                        : "img_box"
                    }
                    data-action="reveal-content"
                  >
                    <img
                      src={baseUrl + project.mainFile}
                      alt="프로젝트 이미지"
                    />
                  </div>
                  <div className="txt_box" data-action="reveal-content">
                    <strong className="tlt">{project.prjNm}</strong>
                    <div className="device_info">
                      {project.prjTagCd?.map((prjTags, i) => (
                        <span className="name" key={i}>
                          {prjTags}
                        </span>
                      ))}
                    </div>
                    <div className="more">
                      <button
                        type="button"
                        title="더보기"
                        className="btn_o_line"
                      >
                        VIEW MORE
                      </button>
                    </div>
                  </div>
                </span>
              </Link>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default Project;
