import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import popupSlice from './components/common/popupSlice';
import alertSlice from './components/pages/contact/alertSlice';
import contactSlice from './components/pages/contact/contactItemSlice';
import contactListSlice from './components/pages/contact/contactListSlice';
import cultureSlice from './components/pages/culture/cultureSlice';
import mainSlice from './components/pages/main/mainSlice';
import noticeSlice from './components/pages/notice/noticeSlice';
import projectDetailSlice from './components/pages/project/detailSlice';
import projectSlice from './components/pages/project/projectSlice';
import loadingSlice from './components/common/loadingSlice';

//const logger = createLogger();

const rootReducer = combineReducers({
    loading: loadingSlice.reducer,
    main: mainSlice.reducer,
    notice: noticeSlice.reducer,
    culture: cultureSlice.reducer,
    project: projectSlice.reducer,
    projectDetail: projectDetailSlice.reducer,
    contact: contactSlice.reducer,
    modal: alertSlice,
    contactList: contactListSlice,
    popup: popupSlice,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
    //middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
    devTools: process.env.NODE_ENV !== 'production',
    // preloadedState: initialState,
    //enhancers: defaultEnhancers => [...defaultEnhancers],
});

export const useAppSelector = useSelector;
export const useAppDispatch = () => useDispatch();

export default store;
