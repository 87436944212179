import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { titleAction } from '../../../assets/js/textAnimation';
import { useAppDispatch, useAppSelector } from '../../../store';
import Loading from '../../common/Loading';
import ScrollAni from '../../common/ScrollAni';
import { actIsAdd, getNotice } from './noticeSlice';

const Notice = () => {
    // title animation
    setTimeout(() => {
        const textElements = document.querySelectorAll("[data-action='action-title-y']");
        titleAction('y', textElements, 0.5, { default: 0.3, repeat: 0.2843 });
    }, 0.5);

    const listOpen = useCallback(event => {
        const btn = event.target.closest('.btn_open_cont');
        const content = btn.nextSibling;

        document.querySelectorAll('.btn_open_cont').forEach(bt => {
            if (bt !== btn) bt.classList.remove('active');
        });
        document.querySelectorAll('.cont').forEach(ct => {
            if (ct !== content) ct.classList.remove('active');
        });

        btn.classList.toggle('active');
        content.classList.toggle('active');
    }, []);

    const { ref: target, inView } = useInView({
        /* Optional options */
        threshold: 0,
    });

    const dispatch = useAppDispatch();

    const {
        list: noticeList,
        totalPageCount,
        dataTotalCnt,
    } = useAppSelector(state => state.notice);

    const { loading } = useAppSelector(state => state.loading);

    let [pageIndex, setPageIndex] = useState(1);

    useEffect(() => {
        dispatch(getNotice(pageIndex));
    }, []);

    useEffect(() => {
        if (inView && pageIndex <= totalPageCount) {
            let pageIncrease = pageIndex + 1;
            setPageIndex(pageIncrease);
            dispatch(actIsAdd(true));
            dispatch(getNotice(pageIncrease));
        }
    }, [inView]);

    return (
        <div className="notice scroll">
            {loading ? (
                <Loading />
            ) : (
                <section className="content">
                    <div className="visual">
                        <h2 className="tlt" data-action="action-title-y">
                            NOTICE
                        </h2>
                    </div>
                    {/* <List /> */}
                    <div className="page">
                        <strong className="total_num">
                            총 <span>{dataTotalCnt}</span>건
                        </strong>
                        <ul className="written_list">
                            {noticeList?.map((notice, i) => (
                                <li key={i}>
                                    <button
                                        type="button"
                                        title="내용 열기"
                                        className="btn_open_cont"
                                        onClick={listOpen}
                                    >
                                        <strong className="tlt">{notice?.title}</strong>
                                        <span className="date">
                                            {notice?.createDate}
                                            <i className="ico_36_openList"></i>
                                        </span>
                                    </button>
                                    <div className="cont">
                                        <p
                                            className="desc"
                                            dangerouslySetInnerHTML={{
                                                __html: notice?.contents,
                                            }}
                                        ></p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            )}
            <div ref={target} />
            <ScrollAni />
        </div>
    );
};

export default React.memo(Notice);
