import React from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const InSight = () => {
  return (
    <>
      {/* intro */}
      <div className="bg_box1">
        <section className="content intro">
          <div className="txt_wrap">
            <h2 className="tlt">
              <span className="top" data-action="action-title-y">
                WHO WE ARE
              </span>
              <span className="btm" data-action="action-title-y">
                INSIGHT
              </span>
            </h2>
            <p className="desc" data-action="action-title-y">
              새롭고 독창적인 탐색, <br />
              인스플래닛은 경험을 만들어갑니다.
            </p>
          </div>
        </section>
      </div>

      {/* service */}
      <section className="content service">
        <div className="sub_cont" data-action="reveal-content">
          <div className="txt_wrap">
            <h2 className="tlt">
              <span className="top">SERVICE</span>
            </h2>
          </div>
          <div className="sub_tlt">
            <strong className="tlt">
              UI・UX
              <br /> CONSULTING
            </strong>
            <p className="desc">
              고객이 추구하는 가치에 대한 깊은 이해와
              <br />
              사용자 분석을 통해 UIUX 전략을 도출합니다.
            </p>
          </div>
          <Swiper
            spaceBetween={16}
            centeredSlides={false}
            slidesPerView={"auto"}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              1280: {
                spaceBetween: 96,
                slidesPerView: "auto",
              },
            }}
            className="img_box"
          >
            <SwiperSlide className="img">
              <Link to="/project/detail/50" title="페이지 이동">
                <div className="link_box">
                  <span className="sub">1회용 컵 보증금 제도</span>
                  <strong className="tlt">
                    시스템 구축 및 <br />
                    UI・UX
                  </strong>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="img">
              <Link to="/project/detail/46" title="페이지 이동">
                <div className="link_box">
                  <span className="sub">마이홈즈</span>
                  <strong className="tlt">
                    서비스 확대 및 <br />
                    UI・UX 개선
                  </strong>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="img">
              <Link to="/project/detail/41" title="페이지 이동">
                <div className="link_box">
                  <span className="sub">신한금융지주OPEN API PORTAL</span>
                  <strong className="tlt">데이터 시각화 및 대시보드</strong>
                </div>
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="sub_cont" data-action="reveal-content">
          <div className="sub_tlt">
            <strong className="tlt">
              STRATEGY
              <br />
              PLANNING
            </strong>
            <p className="desc">
              새로운 관점과 논리적 사고로 발견된 가치를
              <br />
              차별화된 전략과 체계적인 설계로 완성합니다.
            </p>
          </div>
          <Swiper
            spaceBetween={16}
            centeredSlides={false}
            slidesPerView={"auto"}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              1280: {
                spaceBetween: 96,
                slidesPerView: "auto",
              },
            }}
            className="img_box"
          >
            <SwiperSlide className="img width">
              <Link to="/project/detail/42" title="페이지 이동">
                <div className="link_box">
                  <span className="sub">세친구</span>
                  <strong className="tlt">서비스 분석 및 문서화</strong>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="img height">
              <Link to="/project/detail/39" title="페이지 이동">
                <div className="link_box">
                  <span className="sub">군인공제회 자금관리 시스템</span>
                  <strong className="tlt">
                    데이터 제공 및 처리에 대한
                    <br />
                    유연한 설계
                  </strong>
                </div>
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="sub_cont" data-action="reveal-content">
          <div className="sub_tlt">
            <strong className="tlt">
              VISUAL
              <br />
              STRATEGY
            </strong>
            <p className="desc">
              트랜디한 감성으로 브랜드 가치를 높이고,
              <br />
              종합적인 분석을 통해 효과적인 디지털 전략을 제시합니다.
            </p>
          </div>
          <Swiper
            spaceBetween={16}
            centeredSlides={false}
            slidesPerView={"auto"}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              1280: {
                spaceBetween: 96,
                slidesPerView: "auto",
              },
            }}
            className="img_box"
          >
            <SwiperSlide className="img height">
              <Link to="/project/detail/49" title="페이지 이동">
                <div className="link_box">
                  <span className="sub">헤이영캠퍼스 고도화</span>
                  <strong className="tlt">
                    다크테마 및<br />
                    커스텀 기능 제공
                  </strong>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="img">
              <Link to="/project/detail/48" title="페이지 이동">
                <div className="link_box">
                  <span className="sub">신한플러스 해외주식소수점투자</span>
                  <strong className="tlt">UI 개선 및 고도화</strong>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="img">
              <Link to="/project/detail/38" title="페이지 이동">
                <div className="link_box">
                  <span className="sub">트래멘토</span>
                  <strong className="tlt">
                    브랜드 포지셔닝을 위한
                    <br />
                    디자인 방향성 수립
                  </strong>
                </div>
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="sub_cont" data-action="reveal-content">
          <div className="sub_tlt">
            <strong className="tlt">
              WEB/APP
              <br />
              SERVICE
            </strong>
            <p className="desc">
              서비스에 대한 높은 이해와 디바이스의 특성을 고려한
              <br />
              최적화된 설계로 PC와 모바일의 특화된 경험을 제공합니다.
            </p>
          </div>
          <Swiper
            spaceBetween={16}
            centeredSlides={false}
            slidesPerView={"auto"}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              1280: {
                spaceBetween: 96,
                slidesPerView: "auto",
              },
            }}
            className="img_box"
          >
            <SwiperSlide className="img height">
              <Link to="/project/detail/45" title="페이지 이동">
                <div className="link_box">
                  <span className="sub">BIZ PAY</span>
                  <strong className="tlt">간편결제 솔루션</strong>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide className="img width">
              <Link to="/project/detail/43" title="페이지 이동">
                <div className="link_box">
                  <span className="sub">보건복지부 포털 복지로</span>
                  <strong className="tlt">UI・UX 개선 및 사이트 구축</strong>
                </div>
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* team */}
      <section className="content team" data-action="reveal-content">
        <div className="txt_wrap">
          <h2 className="tlt">
            <span className="top">TEAM</span>
          </h2>
          <p className="desc">
            다양한 경험과 특별함이 모여 놀라운 시너지를 만듭니다.
          </p>
        </div>
        <ul className="ourteam">
          <li className="our">
            <span className="img"></span>
            <strong className="tlt">PLANNING</strong>
            <p className="desc">
              새로운 관점에서 바라본 가치와 서비스를 다각도로 분석하여 체계적인
              설계로 완성합니다.
            </p>
          </li>
          <li className="our">
            <span className="img"></span>
            <strong className="tlt">DESIGN</strong>
            <p className="desc">
              직관적인 사용자 인터페이스를 고려하며 트랜디한 감성과 차별화된
              디자인 전략을 제공합니다.
            </p>
          </li>
          <li className="our">
            <span className="img"></span>
            <strong className="tlt">PUBLISHING</strong>
            <p className="desc">
              웹표준과 접근성을 기반으로 최적의 인터렉션을 제공함으로써 풍부한
              사용자 경험을 선사합니다.
            </p>
          </li>
        </ul>
      </section>
    </>
  );
};

export default InSight;
