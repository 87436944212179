import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useAppDispatch, useAppSelector } from "../../../store";
import Loading from "../../common/Loading";
import { actIsAdd, getCulture } from "./cultureSlice";

const FilterItem = () => {
  const dispatch = useAppDispatch();

  const { list: cultureList, totalPageCount } = useAppSelector(
    (state) => state.culture
  );
  const { loading } = useAppSelector((state) => state.loading);
  let [currentPageNo, setCurrentPageNo] = useState(1);
  let { ref: target, inView } = useInView({ threshold: 0 });

  useEffect(() => {
    dispatch(getCulture(currentPageNo));
  }, [dispatch]);

  useLayoutEffect(() => {
    if (inView && currentPageNo <= totalPageCount) {
      let pageIncrease = currentPageNo + 1;
      dispatch(actIsAdd(true));
      dispatch(getCulture(pageIncrease));
      setCurrentPageNo(pageIncrease);
    }
  }, [inView]);

  const lazyImg = useCallback(() => {
    setTimeout(() => {
      const projectItem = document.querySelectorAll(".tab_item_wrap li");
      projectItem.forEach((projectItem) => {
        projectItem.classList.add("active");
      });
    }, 300);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ul className="tab_item_wrap">
          {cultureList?.map((culture) => (
            <li key={culture.bbsSeq}>
              <a
                role="button"
                href={culture.urlAddr !== "" ? culture.urlAddr : "#!"}
                target="_blank"
                className="item"
                rel="noreferrer"
              >
                <img src={culture.mainFile} alt="" onLoad={lazyImg} />
                <div className="txt_wrap">
                  <h3 className="tlt">
                    {culture.bbsTitNm}
                    {culture.urlAddr ? <i className="ico_28_insta" /> : null}
                  </h3>
                </div>
              </a>
            </li>
          ))}
        </ul>
      )}
      <div ref={target} />
    </>
  );
};

export default React.memo(FilterItem);
