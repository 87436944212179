import React from 'react';

const Logo = () => {
    return (
        <a href="/" title="페이지 이동">
            <h1 className="logo">
                <span className="blind">in'splanet</span>
            </h1>
        </a>
    );
};

export default Logo;
