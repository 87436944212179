import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from '../../../store';
import { listOneFn, listTwoFn } from './contactListSlice';

const FilterList = () => {

  const dispatch = useAppDispatch();
  const contactList = useSelector((state) => state.contactList);

  const onClickListOne=()=>{
    dispatch(listOneFn());
    window.scrollTo(0, 0);
  }

  const onClickListTwo=()=>{
    dispatch(listTwoFn());
    window.scrollTo(0, 0);
  }

  return (
    <ul className="tab_list_wrap">
      <li>
        <button
          type="button"
          className={contactList.ListOne ? "list active" : "list"}
          tabIndex={contactList.tabIndexOne}
          data-list="prjRequest"
          onClick={onClickListOne}
        >
          프로젝트 의뢰
        </button>
      </li>
      <li>
        <button
          type="button"
          className={contactList.ListTwo ? "list active" : "list"}
          tabIndex={contactList.tabIndexTwo}
          data-list="workRequest"
          onClick={onClickListTwo}
        >
          입사지원
        </button>
      </li>
    </ul>
  );
};

export default FilterList;