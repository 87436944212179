import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../store';
import { actIsLoading } from '../components/common/loadingSlice';

// axios instance 생성
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
});

const AxiosInterceptor = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    //비동기 싱크 맞춰주기 위함.
    const [isSet, setIsSet] = useState(false);

    useEffect(() => {
        const pending = config => {
            dispatch(actIsLoading(true));
            return config;
        };

        const fulfilled = response => {
            dispatch(actIsLoading(false));
            //200
            return response;
        };

        const rejected = error => {
            dispatch(actIsLoading(false));
            //500 error
            if (error.response.status.toString().startsWith('5', 0)) {
                navigate('/noAccess');
            }
            return Promise.reject(error);
        };

        const reqInterceptor = instance.interceptors.response.use(pending);
        const resInterceptor = instance.interceptors.response.use(fulfilled, rejected);
        setIsSet(true);

        return () => {
            instance.interceptors.request.eject(reqInterceptor);
            instance.interceptors.response.eject(resInterceptor);
        };
    }, []);

    return isSet && children;
};

export default instance;
export { AxiosInterceptor };
