import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        actIsLoading: (state, { payload }) => {
            state.loading = payload;
        },
    },
});

export const { actIsLoading } = loadingSlice.actions;
export default loadingSlice;
