import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { popupHide } from './popupSlice';

const PerUsePopup = () => {
  const popup = useSelector((state) => state.popup);

  const dispatch = useAppDispatch();

  const onClickPopupClose = () => {
    dispatch(popupHide());
  };

  return (
    <div
      className={popup.useMode ? 'popup_wrap active' : 'popup_wrap'}
      data-popcont="PerUsePopup"
    >
      <div className="dim" onClick={onClickPopupClose}></div>
      <div className="inner">
        {/* title */}
        <div className="pop_header" id="PerUseHeader">
          <strong className="tlt">개인정보 수집∙이용 동의</strong>
        </div>
        {/* content */}
        <div className="pop_content" id="PerUseContent">
          <p className="text-information1">
            회사는 다음과 같은 목적을 위하여 개인정보를 수집하고 있으며, 회사
            내부 방침에 따라 수집된 개인정보를 보유기간 이후 지체없이
            파기합니다.
          </p>

          <table className="table-type1">
            <caption className="blind">수집 목적 및 항목, 기간</caption>
            <colgroup>
              <col width="24%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>수집 목적</th>
                <td>프로젝트 의뢰 및 인사지원에 대한 응대</td>
              </tr>
              <tr>
                <th>수집 항목</th>
                <td>
                  <ul className="list-type1">
                    <li>프로젝트 의뢰 : 성명, 이메일, 연락처, 회사명</li>
                    <li>입사지원 : 성명, 이메일, 연락처</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>보유 기간</th>
                <td>
                  <strong>접수 후 1년간 보유</strong>
                </td>
              </tr>
            </tbody>
          </table>

          <p className="text-information2">
            정보주체는 개인정보 수집에 대한 동의를 거부할 권리가 있습니다.
            <br />
            단, 미동의 시 문의 및 제안 서비스를 이용하실 수 없습니다.
          </p>
        </div>
        <div className="pop_footer">
          <div className="btn_wrap">
            <button
              type="button"
              className="btn_m_line"
              onClick={onClickPopupClose}
              data-pop-confirm
            >
              확인
            </button>
          </div>
        </div>
        <button
          type="button"
          title="팝업 닫기"
          className="btn_close"
          onClick={onClickPopupClose}
        ></button>
      </div>
    </div>
  );
};
export default PerUsePopup;
