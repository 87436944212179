import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../api/axios';
import requests from '../../../api/requests';

const initialState = {
    list: [],
    totalPageCount: '',
    isAdd: false,
};

export const getCulture = createAsyncThunk('culture/getCultures', async currentPageNo => {
    const res = await axios.get(requests.CULTURE_LIST + '?pageIndex=' + currentPageNo + '&pageUnit=' + '6');
    let culturesData = res.data.data;
    return culturesData;
});

const cultureSlice = createSlice({
    name: 'culture',
    initialState,
    reducers: {
        actIsAdd: (state, { payload }) => {
            state.isAdd = payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(getCulture.fulfilled, (state, { payload }) => {
            state.list = state.isAdd ? [...state.list, ...payload.list] : payload.list;
            state.dataTotalCnt = payload.totalCount;
            state.totalPageCount = payload.pagination.totalPageCount;
        });
    },
});

export const { actIsAdd } = cultureSlice.actions;
export default cultureSlice;
