import { createSlice } from '@reduxjs/toolkit';


const ScrollLock = () => {
  document.body.style.cssText = `
  position: fixed; 
  top: -${window.scrollY}px;
  overflow-y: scroll;
  width: 100%;`;
}

const ScrollUnlock = () => {
  const scrollY = document.body.style.top;
  document.body.style.cssText = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
}

const initialState = {
  infoMode: false,
  collectMode: false,
  useMode: false,
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    shiowInfo: (state) => {
      state.infoMode = true;
      ScrollLock();
    },
    showCollect: (state) => {
      state.collectMode = true;
      ScrollLock();
    },
    showUse: (state) => {
      state.useMode = true;
      ScrollLock();
    },
    popupHide: (state) => {
      state.infoMode = false;
      state.collectMode = false;
      state.useMode = false;
      ScrollUnlock();
    },
  },
});

export const { shiowInfo, showCollect, showUse, popupHide } =
  popupSlice.actions;
export const selectPopup = (state) => state.modal;

export default popupSlice.reducer;
