import React from 'react';

const Loading = () => {
    return (
        <section className="loading">
            <div className="lds-ellipsis">
                <div className="item1"></div>
                <div className="item2"></div>
                <div className="item3"></div>
                <div className="item4"></div>
            </div>
        </section>
    );
};

export default Loading;
