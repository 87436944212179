import React from "react";
import { titleAction } from "../../../assets/js/textAnimation";
import ScrollAni from "../../common/ScrollAni";
import BtnWrap from "./BtnWrap";
import FilterItem from "./FilterItem";

const Culture = ({ contactList, listTwoFn }) => {
  setTimeout(() => {
    const textElements = document.querySelectorAll(
      "[data-action='action-title-y']"
    );
    titleAction("y", textElements, 0.5, { default: 0.3, repeat: 0.2843 });
  }, 0.5);

  return (
    <div className="culture scroll">
      <section className="content">
        <div className="visual layout1" data-action="action-title-y">
          <h2 className="tlt">CULTURE</h2>
          <BtnWrap contactList={contactList} listTwoFn={listTwoFn} />
        </div>
        <div className="page">
          <FilterItem />
        </div>
      </section>
      <ScrollAni />
    </div>
  );
};

export default Culture;
